import { useRecoilValue } from 'recoil';

import Button from '@mui/material/Button';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import useConfirmModal from 'src/hooks/useConfirmModal';
import { confirmModalState } from 'src/states/confirmModal';

import StyledDialog from './StyledDialog';
import StyledDialogActions from './StyledDialogActions';
import StyledDialogContent from './StyledDialogContent';

const ConfirmModal = (): JSX.Element => {
  const {
    visibility,
    title,
    description,
    confirmButtonText,
    showCancelButton,
  } = useRecoilValue(confirmModalState);
  const { handleCancel, handleConfirm } = useConfirmModal();

  return (
    <StyledDialog open={visibility} onClose={handleCancel}>
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <StyledDialogContent>
        <DialogContentText id="alert-dialog-description">
          {description}
        </DialogContentText>
      </StyledDialogContent>
      <StyledDialogActions>
        {showCancelButton && (
          <Button onClick={handleCancel} data-test-id="cancel-dialog-btn">
            Cancel
          </Button>
        )}
        <Button
          onClick={handleConfirm}
          autoFocus
          variant="contained"
          color="primary"
          data-test-id="confirmation-dialog-btn"
        >
          {confirmButtonText || 'Confirm'}
        </Button>
      </StyledDialogActions>
    </StyledDialog>
  );
};

export default ConfirmModal;
