import cookie from 'js-cookie';

import { authApi, authKey, getUserInfoFromToken } from 'src/http';
import { Me } from 'src/interfaces/me';
import { DEFAULT_ME } from 'src/states/defaults';

interface LoginPayload {
  access_token: string;
  token_type: string;
}

export const login = async (
  username: string,
  password: string
): Promise<Me> => {
  try {
    const { data } = await authApi.post<LoginPayload>('login', {
      username,
      password,
    });

    cookie.set(authKey, data.access_token, {
      expires: new Date(Date.now() + 1000 * 60 * 60 * 24),
    });

    const user = getUserInfoFromToken(data.access_token);

    return {
      status: 'SIGNED',
      user,
    };
  } catch (error) {
    cookie.remove(authKey);
    throw error;
  }
};

export const logout = (): Me => {
  cookie.remove(authKey);

  return DEFAULT_ME;
};
