import { styled } from '@mui/material/styles';

const ButtonList = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 2px;
  list-style: none;
  margin: 0;
  padding: 0;
  user-select: none;

  & ~ & {
    margin-top: 8px;
  }
`;

export default ButtonList;
