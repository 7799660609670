import Box from '@mui/material/Box';
import Slider, { SliderProps } from '@mui/material/Slider';
import { css, styled } from '@mui/material/styles';

import { isArray } from 'src/utils/typeHelper';

interface Props {
  totalFrames: number;
  value: number | number[];
  onChange: SliderProps['onChange'];
  fullWidth?: boolean;
  disabled?: boolean;
}

const styleViewerFrameNavigator = css`
  outline: 3px solid black;
  opacity: 1;
`;

const MIN_VALUE = 1;

const OutlinedText = (number: number, anchorText: 'start' | 'middle') => (
  <svg
    width={24}
    height={20}
    style={{
      pointerEvents: 'none',
      userSelect: 'none',
      fontSize: 12,
    }}
  >
    <text
      textAnchor={anchorText}
      x={12}
      y={15}
      width={24}
      height={20}
      stroke="#000000"
      strokeWidth={6}
      strokeLinejoin="round"
      strokeLinecap="round"
    >
      {number}
    </text>
    <text
      textAnchor={anchorText}
      x={12}
      y={15}
      width={24}
      height={20}
      stroke={'#fff'}
    >
      {number}
    </text>
  </svg>
);

const FrameNavigator = ({
  totalFrames,
  value,
  onChange,
  fullWidth,
  disabled,
}: Props): JSX.Element => {
  const isRange = isArray(value);

  return (
    <Container $fullWidth={fullWidth}>
      <Box>
        {isRange ? <Text>{MIN_VALUE}</Text> : OutlinedText(MIN_VALUE, 'start')}
      </Box>
      <Box sx={{ flex: 1 }}>
        <StyledSlider
          $isRange={isRange}
          size="small"
          valueLabelDisplay="on"
          value={value}
          valueLabelFormat={(val: number) => <>{val + 1}</>}
          min={0}
          max={totalFrames - 1}
          onChange={onChange}
          track={isRange ? 'normal' : false}
          disableSwap={true}
          disabled={disabled}
          data-test-id={`${isRange ? 'multiFramePolygon' : 'viewer'}-slider`}
        />
      </Box>
      <Box>
        {isRange ? (
          <Text>{totalFrames}</Text>
        ) : (
          OutlinedText(totalFrames, 'middle')
        )}
      </Box>
    </Container>
  );
};

export default FrameNavigator;

const Container = styled(Box)<{ $fullWidth: boolean | undefined }>(
  ({ theme, $fullWidth }) => `
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${theme.spacing(1)} ${$fullWidth ? '2px' : 0};
  gap: ${theme.spacing(1)};
  width: ${$fullWidth ? '100%' : 'unset'};
`
);

const Text = styled('div')`
  font-size: 10px;
`;

const StyledSlider = styled(Slider)<{ $isRange: boolean }>(
  ({ $isRange }) => `
  transform: translateY(1px);
  height: 1px;
  opacity: 1;
  pointer-events: auto;

  & .MuiSlider-thumb {
    width: 8px;
    height: 8px;
    color: ${$isRange ? '#96edff' : '#fff'};
    outline: 2px solid ${$isRange ? 'unset' : '#000'};

    &:hover,
    &.Mui-focusVisible {
      box-shadow: 0 0 0 6px rgb(255 255 255 / 16%);

      & .MuiSlider-valueLabel {
        background-color: #01aed4;
      }
    }

    & .MuiSlider-valueLabel {
      top: -8px;
      padding: 2px 4px;
        
      &:before {
        transform: translate(-50%, 20%) rotate(45deg);
        z-index: -1;
      }
    }
  }

  & .MuiSlider-rail {
    color: #8993ae;
    transform: unset;
    ${!$isRange ? styleViewerFrameNavigator : ''}
  }

  & .MuiSlider-track {
    color: #96edff;
    transform: scaleY(3);
  }`
);
