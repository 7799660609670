import { useRecoilValueLoadable } from 'recoil';

import { styled } from '@mui/material/styles';

import ShortcutItem from 'src/components/modals/ShortcutsModal/ShortcutItem';
import shortcutState from 'src/states/shortcut';

const ShortcutAlert = (): JSX.Element | null => {
  const activatedShortcut = useRecoilValueLoadable(shortcutState.activated);

  if (!activatedShortcut.contents) {
    return null;
  }

  return (
    <Container>
      <ShortcutItem shortcut={activatedShortcut.contents} />
    </Container>
  );
};

export default ShortcutAlert;

const Container = styled('div')`
  display: flex;
  height: 100%;
`;
