import { useCallback, useEffect, useState } from 'react';

import { useRecoilValue_TRANSITION_SUPPORT_UNSTABLE } from 'recoil';

import { JobListItem } from 'src/services/job';
import jobListState, { useRefreshJobList } from 'src/states/jobList';

interface LocalJobListItem {
  job: JobListItem;
  index: number;
  flagged: boolean;
  reported: boolean;
}

interface Return {
  localJobList: LocalJobListItem[];
  onToggleFlagged: (jobId: string) => void;
  onReportJob: (jobId: string) => void;
}

/**
 * This is for returning copied state of jobIdListState.jobList,
 * and more additional fields like below.
 *
 * - index : For displaying fixed index while applying filters.
 * - flagged : For updating locally, for not to updating the value of recoil selector directly.
 *
 * @returns Return
 */
const useLocalJobList = (): Return => {
  const jobList = useRecoilValue_TRANSITION_SUPPORT_UNSTABLE(
    jobListState.current
  );
  const [localJobList, setLocalJobList] = useState<LocalJobListItem[]>([]);
  const refreshJobList = useRefreshJobList();

  useEffect(() => {
    const jobItems = jobList.map((job, index) => ({
      job,
      index,
      flagged: !!job.flagged,
      reported: !!job.reported,
    }));
    setLocalJobList(jobItems);
  }, [jobList]);

  const onToggleFlagged = useCallback((jobId: string) => {
    setLocalJobList(prev => {
      return prev.map(jobListItem => {
        if (jobListItem.job.id === jobId) {
          jobListItem.flagged = !jobListItem.flagged;
        }

        return jobListItem;
      });
    });
  }, []);

  const onReportJob = useCallback(
    (jobId: string) => {
      setLocalJobList(prev => {
        return prev.map(jobListItem => {
          if (jobListItem.job.id === jobId) {
            jobListItem.reported = true;
          }
          return jobListItem;
        });
      });
      refreshJobList();
    },
    [refreshJobList]
  );

  return { localJobList, onReportJob, onToggleFlagged };
};

export default useLocalJobList;
