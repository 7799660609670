import {
  useState,
  useMemo,
  useRef,
  RefObject,
  ComponentType,
  MouseEvent,
  PropsWithChildren,
} from 'react';

import { isComplexPolygon } from '@lunit/is-complex-polygon';
import { isPolygonAreaGreaterThanArea } from '@lunit/is-polygon-area-greater-than-area';
import {
  constSelector,
  useRecoilState,
  useRecoilValue,
  useRecoilValueLoadable_TRANSITION_SUPPORT_UNSTABLE,
} from 'recoil';
import useResizeObserver from 'use-resize-observer';

import { styled } from '@mui/material/styles';

import {
  adjustWindowCenter,
  adjustWindowWidth,
  updateViewport,
  zoomMiddleCenter,
} from '@InsightViewer/behaviors/updateViewport';
import { ContourDrawer } from '@InsightViewer/components/ContourDrawer';
import { ContourHover } from '@InsightViewer/components/ContourHover';
import {
  ContourViewer,
  ContourViewerProps,
} from '@InsightViewer/components/ContourViewer';
import { CornerstoneViewer } from '@InsightViewer/components/CornerstoneViewer';
import {
  InsightViewerContainer,
  RightBottomHolder,
} from '@InsightViewer/components/InsightViewerContainer';
import { LineDrawer } from '@InsightViewer/components/LineDrawer';
import {
  LineViewer,
  LineViewerProps,
} from '@InsightViewer/components/LineViewer';
import {
  PointDrawerProps,
  PointViewer,
} from '@InsightViewer/components/PointViewer';
import { useInsightViewerSync } from '@InsightViewer/hooks/useInsightViewerSync';
import { useViewerInteractions } from '@InsightViewer/interactions/useViewerInteractions';
import { CornerstoneViewerLike, Point } from '@InsightViewer/types';

import IssuePointPin from 'src/components/Issue/IssuePointPin';
import { ViewportInfoLabel } from 'src/components/opt-components/ViewportInfoLabel';
import { CPCViewerHeader } from 'src/components/viewers/cpc/CPCViewerHeader';
import useImagePath from 'src/hooks/useImagePath';
import useShortcuts from 'src/hooks/useShortcuts';
import { LocalFinding, FindingContour, FindingShape } from 'src/interfaces';
import { ShortcutDefinition } from 'src/interfaces/shortcut';
import controlState from 'src/states/control';
import imageState from 'src/states/image';
import issuesState from 'src/states/issues';
import { jobState } from 'src/states/job';
import { projectState } from 'src/states/project';
import FindingUtils from 'src/utils/finding';

/**
 * INFO: previously we used to have Point and Line viewer and Line drawer
 * but it was requested to remove them
 */

type ViewerRef = RefObject<CornerstoneViewer>;

export interface XRayViewerProps {
  findings: LocalFinding[];
  findingIndex?: number;
  setFindingIndex: (x?: number) => void;
  addFinding: (x: LocalFinding) => void;
}

function voidFunc() {
  /* empty function */
}

// squared threshold of the long line
const LONG_LINE_THRESHOLD = 50;
const IMAGE_KEY = 'frontal';

const isLineLongEnough = (line: Point[]): boolean => {
  const dist = line
    .map((point, idx) => {
      const [x, y] = point;
      const nextPoint = line[idx + 1];
      if (nextPoint) {
        const [nextX, nextY] = nextPoint;
        return (x - nextX) ** 2 + (y - nextY) ** 2;
      } else return 0;
    })
    .reduce((acc, cur) => acc + cur, 0);
  return dist > LONG_LINE_THRESHOLD;
};

export function XRayViewer({
  findings,
  findingIndex,
  setFindingIndex,
  addFinding,
}: XRayViewerProps): JSX.Element {
  const isCPCProject = useRecoilValue(projectState.isCPC);

  const job = useRecoilValue(jobState.current);
  const imagePath = useImagePath({ job, imageKey: IMAGE_KEY });
  const image = useRecoilValue(
    !!imagePath
      ? imageState.cornerstoneImage(imagePath)
      : constSelector(undefined)
  );

  const control = useRecoilValue(controlState.current);
  const resetTime = useRecoilValue(controlState.resetTime);
  const invert = useRecoilValue(controlState.invert);
  const flip = useRecoilValue(controlState.flip);

  const [localIssuePosition, setLocalIssuePosition] = useRecoilState(
    issuesState.localIssuePosition
  );

  const issuesLoadable = useRecoilValueLoadable_TRANSITION_SUPPORT_UNSTABLE(
    issuesState.pointList
  );
  const issuePointList =
    issuesLoadable.state === 'hasValue' ? issuesLoadable.contents : [];
  const activatedId = useRecoilValue(issuesState.activatedId);
  const focusedIssue =
    issuePointList.find(({ issueId }) => issueId === activatedId) || null;

  const [interactionElement, setInteractionElement] =
    useState<HTMLElement | null>(null);

  const { ref, width, height } = useResizeObserver<HTMLDivElement>();
  const currentContourRef = useRef<FindingContour | null>(null);

  const shortcuts = useMemo<ShortcutDefinition[]>(
    () => [
      {
        shortcut: 'zoomIn',
        callback: () => {
          const increment = 0.25;
          updateViewport(viewerRef, zoomMiddleCenter(increment));
        },
      },
      {
        shortcut: 'zoomOut',
        callback: () => {
          const increment = -0.25;
          updateViewport(viewerRef, zoomMiddleCenter(increment));
        },
      },
      {
        shortcut: 'adjustWindowCenterUp',
        callback: () => {
          const increment = 0.05;
          updateViewport(viewerRef, adjustWindowCenter(increment));
        },
      },
      {
        shortcut: 'adjustWindowCenterDown',
        callback: () => {
          const increment = -0.05;
          updateViewport(viewerRef, adjustWindowCenter(increment));
        },
      },
      {
        shortcut: 'adjustWindowWidthUp',
        callback: () => {
          const increment = 0.05;
          updateViewport(viewerRef, adjustWindowWidth(increment));
        },
      },
      {
        shortcut: 'adjustWindowWidthDown',
        callback: () => {
          const increment = -0.05;
          updateViewport(viewerRef, adjustWindowWidth(increment));
        },
      },
    ],
    []
  );
  useShortcuts(shortcuts);

  const viewerRef = useRef<CornerstoneViewerLike>(null);
  const inProgress = false;
  const { cornerstoneRenderData, updateCornerstoneRenderData } =
    useInsightViewerSync();
  const interactions = useViewerInteractions(
    ['zoom', control === 'brightness' ? 'adjust' : control],
    {
      element: interactionElement,
    }
  );

  const imageWidth = cornerstoneRenderData?.image?.width || 0;
  const imageHeight = cornerstoneRenderData?.image?.height || 0;

  const contours = useMemo(() => {
    return findings
      .map(FindingUtils.getContourFromFinding(imageWidth, imageHeight))
      .filter(contour => !contour.hidden);
  }, [findings, imageHeight, imageWidth]);

  const focusedContour =
    contours.find(contour => contour.id === findingIndex) || null;

  const polygons = useMemo(
    () =>
      contours.filter(
        ({ shape, viewOnly }) => shape === FindingShape.POLYGON && !viewOnly
      ),
    [contours]
  );

  const viewOnlyPolygons = useMemo(
    () =>
      contours.filter(
        ({ shape, viewOnly }) => shape === FindingShape.POLYGON && !!viewOnly
      ),
    [contours]
  );

  const lines = useMemo(
    () =>
      contours.filter(
        ({ shape, viewOnly }) => shape === FindingShape.LINE && !viewOnly
      ),
    [contours]
  );

  const viewOnlyLines = useMemo(
    () =>
      contours.filter(
        ({ shape, viewOnly }) => shape === FindingShape.LINE && !!viewOnly
      ),
    [contours]
  );

  const points = useMemo(
    () =>
      contours.filter(
        ({ shape, viewOnly }) => shape === FindingShape.POINT && !viewOnly
      ),
    [contours]
  );
  const viewOnlyPoints = useMemo(
    () =>
      contours.filter(
        ({ shape, viewOnly }) => shape === FindingShape.POINT && !!viewOnly
      ),
    [contours]
  );

  const handleAddContour =
    (shape: FindingShape.POLYGON | FindingShape.LINE) => (contour: Point[]) => {
      //TODO: 단순 클릭 여부를 판별하는 더 좋은 방법이 있을지도
      if (
        shape === FindingShape.POLYGON &&
        (!isPolygonAreaGreaterThanArea(contour) || isComplexPolygon(contour))
      ) {
        setFindingIndex(undefined);
        return;
      }

      if (shape === FindingShape.LINE && !isLineLongEnough(contour)) {
        setFindingIndex(undefined);
        return;
      }

      // TODO: how to handle abbrLabel here
      addFinding({
        index: -1,
        image: IMAGE_KEY,
        alias: '',
        shape,
        points: contour.map(
          ([x, y]): Point => [y / imageHeight, x / imageWidth]
        ),
        confirmed: false,
      });
    };

  const handleAddPoint = (contour: Point[], event: MouseEvent) => {
    event.stopPropagation();

    // TODO: how to handle abbrLabel here
    addFinding({
      index: -1,
      image: IMAGE_KEY,
      alias: '',
      shape: FindingShape.POINT,
      points: contour.map(([x, y]): Point => [y / imageHeight, x / imageWidth]),
      confirmed: false,
    });
  };

  const handleAddIssue = (contour: Point[], event: MouseEvent) => {
    event.stopPropagation();
    const [point] = contour;
    if (!point) return;
    setLocalIssuePosition({
      view: IMAGE_KEY,
      location: point,
    });
  };

  const onContourClick = (contour: FindingContour) => {
    setFindingIndex(contour.id);
  };
  const onContainerClick = () => {
    const contour = currentContourRef.current;
    setFindingIndex(contour?.id);
  };
  const onContourHover = (contour: FindingContour | null) => {
    currentContourRef.current = contour;
  };

  return (
    <View
      ref={ref}
      style={{
        pointerEvents: inProgress ? 'none' : undefined,
      }}
      data-test-id="cxr-viewer"
    >
      {isCPCProject && <CPCViewerHeader />}
      {image && !!width && !!height && (
        <InsightViewerContainer
          ref={setInteractionElement}
          width={width}
          height={height}
          onClick={onContainerClick}
        >
          <CornerstoneViewer
            ref={viewerRef as ViewerRef}
            width={width}
            height={height}
            interactions={interactions}
            flip={flip}
            invert={invert}
            resetTime={resetTime}
            image={image}
            cornerstoneRenderData={cornerstoneRenderData}
            updateCornerstoneRenderData={updateCornerstoneRenderData}
          >
            <ContourHover
              width={width}
              height={height}
              contours={contours}
              hover={interactionElement}
              onFocus={onContourHover}
              cornerstoneRenderData={cornerstoneRenderData}
            />
            <ViewOnlyContourViewer
              width={width}
              height={height}
              contours={viewOnlyPolygons}
              focusedContour={focusedContour}
              cornerstoneRenderData={cornerstoneRenderData}
            />
            <UserContourViewer
              width={width}
              height={height}
              contours={polygons}
              focusedContour={focusedContour}
              cornerstoneRenderData={cornerstoneRenderData}
            />
            <ViewOnlyLineViewer
              width={width}
              height={height}
              contours={viewOnlyLines}
              focusedContour={focusedContour}
              cornerstoneRenderData={cornerstoneRenderData}
            />
            <LineViewer
              width={width}
              height={height}
              contours={lines}
              focusedContour={focusedContour}
              cornerstoneRenderData={cornerstoneRenderData}
            />
            <ViewOnlyPointViewer
              width={width}
              height={height}
              contours={viewOnlyPoints}
              focusedContour={focusedContour}
              cornerstoneRenderData={cornerstoneRenderData}
              interact={false}
            />
            <PointViewer
              width={width}
              height={height}
              contours={points}
              focusedContour={focusedContour}
              cornerstoneRenderData={cornerstoneRenderData}
              interact={false}
            />
            <PointViewer
              width={width}
              height={height}
              contours={issuePointList}
              focusedContour={focusedIssue}
              cornerstoneRenderData={cornerstoneRenderData}
              interact={false}
              pointPinComponent={IssuePointPin}
            />
            {control === FindingShape.POLYGON && (
              <ContourDrawer
                width={width}
                height={height}
                contours={polygons}
                draw={interactionElement}
                onFocus={voidFunc}
                onAdd={handleAddContour(FindingShape.POLYGON)}
                onRemove={onContourClick}
                cornerstoneRenderData={cornerstoneRenderData}
              />
            )}
            {control === FindingShape.LINE && (
              <LineDrawer
                width={width}
                height={height}
                contours={lines}
                draw={interactionElement}
                onFocus={voidFunc}
                onAdd={handleAddContour(FindingShape.LINE)}
                onRemove={onContourClick}
                cornerstoneRenderData={cornerstoneRenderData}
              />
            )}
            {control === FindingShape.POINT && (
              <PointViewer
                width={width}
                height={height}
                contours={[]}
                focusedContour={null}
                cornerstoneRenderData={cornerstoneRenderData}
                interact={true}
                onAdd={handleAddPoint}
                onRemove={onContourClick}
              />
            )}
            {control === 'issuePoint' && (
              <PointViewer
                width={width}
                height={height}
                contours={
                  localIssuePosition?.view === IMAGE_KEY
                    ? [
                        {
                          id: issuePointList.length + 1,
                          polygon: [localIssuePosition.location],
                          shape: FindingShape.POINT,
                        },
                      ]
                    : []
                }
                focusedContour={null}
                cornerstoneRenderData={cornerstoneRenderData}
                interact={true}
                onAdd={handleAddIssue}
                pointPinComponent={IssuePointPin}
              />
            )}
          </CornerstoneViewer>

          <RightBottomHolder>
            <ViewportInfoLabel cornerstoneRenderData={cornerstoneRenderData} />
          </RightBottomHolder>
        </InsightViewerContainer>
      )}
    </View>
  );
}

const View = styled('div')`
  flex: 1;
  overflow: hidden;
  position: relative;
  height: 100%;
`;

const ViewOnlyLineViewer = styled<
  ComponentType<PropsWithChildren<LineViewerProps<FindingContour>>>
>(LineViewer)`
  polyline {
    stroke: var(--viewonly-stroke-color);
  }
  text {
    fill: var(--viewonly-stroke-color);
  }
`;

const ViewOnlyPointViewer = styled<
  ComponentType<PropsWithChildren<PointDrawerProps<FindingContour>>>
>(PointViewer)`
  g {
    color: var(--viewonly-stroke-color);
  }
`;

const UserContourViewer = styled<
  ComponentType<PropsWithChildren<ContourViewerProps<FindingContour>>>
>(ContourViewer)`
  polygon {
    stroke-width: 4px;
  }
`;

const ViewOnlyContourViewer = styled<
  ComponentType<PropsWithChildren<ContourViewerProps<FindingContour>>>
>(ContourViewer)`
  polygon {
    stroke: var(--viewonly-stroke-color);
    stroke-width: 3px;
  }

  text {
    fill: var(--viewonly-stroke-color);
  }
`;
