import { useEffect } from 'react';

import {
  useRecoilValue,
  useRecoilValueLoadable,
  useRecoilState,
  useSetRecoilState,
} from 'recoil';

import useAlert from 'src/hooks/useAlert';
import { ClientErrorCode } from 'src/http/client-error';
import enqueuedJobState from 'src/states/enqueuedJob';
import jobIdListState from 'src/states/jobIdList';
import { useRefreshJobList } from 'src/states/jobList';
import { projectState } from 'src/states/project';

const useEnqueueSingleJob = (): void => {
  const { open: openAlert } = useAlert();

  const refreshJobList = useRefreshJobList();
  const setCurrentJobId = useSetRecoilState(jobIdListState.currentJobId);
  const setIsLastJobInProject = useSetRecoilState(
    jobIdListState.isLastJobInProject
  );

  const needFocus = useRecoilValue(enqueuedJobState.needFocus);
  const isCPCProject = useRecoilValue(projectState.isCPC);
  const enqueuedJobLoadable = useRecoilValueLoadable(enqueuedJobState.current);
  const [preventCPCDoubleEnqueue, setPreventCPCDoubleEnqueue] = useRecoilState(
    enqueuedJobState.preventCPCDoubleEnqueue
  );
  const currentJobId = useRecoilValue(jobIdListState.currentJobId);

  useEffect(() => {
    if (enqueuedJobLoadable.state === 'hasError') {
      const error = enqueuedJobLoadable.contents;

      if (error.code === ClientErrorCode.NO_JOBS_TO_ENQUEUE) {
        setIsLastJobInProject(true);
        openAlert({
          message: currentJobId
            ? 'This is the last job'
            : 'No jobs available to enqueue',
          type: currentJobId ? 'info' : 'error',
        });
      } else {
        openAlert({
          message: `Preparing a new job is failed : ${error?.message || error}`,
          type: 'error',
        });
      }
    } else if (
      enqueuedJobLoadable.state === 'hasValue' &&
      enqueuedJobLoadable.contents !== null
    ) {
      refreshJobList();
      const jobId = enqueuedJobLoadable.contents;
      if (needFocus) {
        setCurrentJobId(jobId);
      }
    } else if (
      enqueuedJobLoadable.state === 'hasValue' &&
      preventCPCDoubleEnqueue
    ) {
      setTimeout(() => {
        refreshJobList();
        setPreventCPCDoubleEnqueue(false);
      }, 200);
    }
  }, [
    enqueuedJobLoadable,
    needFocus,
    openAlert,
    refreshJobList,
    setCurrentJobId,
    setIsLastJobInProject,
    isCPCProject,
    preventCPCDoubleEnqueue,
    setPreventCPCDoubleEnqueue,
    currentJobId,
  ]);
};

export default useEnqueueSingleJob;
