import {
  SelectorAttributes,
  ClaimAssetSchema,
  FindingTexterLabel,
  FindingTogglerLabel,
  FindingSelectorLabel,
  ShapeEnum,
} from '@lunit-io/ctl-api-interface';
import groupBy from 'lodash.groupby';

import { Point } from '@InsightViewer/types';

import {
  LocalFinding,
  FindingContour,
  FindingLabel,
  AssetForm,
  FindingShape,
} from 'src/interfaces';

import { ensure } from './typeHelper';

type ValidatedLabels = { group: string; label: FindingLabel | undefined }[];

type FindingLabelType =
  | FindingTogglerLabel
  | FindingTexterLabel
  | FindingSelectorLabel;

const FindingUtils = (() => {
  const addConfirmedField = (finding: LocalFinding): LocalFinding => ({
    ...finding,
    confirmed: true,
  });

  const removeConfirmedField = ({
    confirmed,
    ...rests
  }: LocalFinding): LocalFinding => rests;

  const getContourFromFinding =
    (imageWidth: number, imageHeight: number) =>
    (finding: LocalFinding): FindingContour => {
      const points = finding.points as Point[];
      return {
        id: finding.index,
        label: `${finding.alias || ''} [${finding.index}]`,
        polygon: points.map(([y, x]) => {
          return [x * imageWidth, y * imageHeight];
        }),
        shape: finding.shape,
        hidden: finding.hidden,
        viewOnly: finding.viewOnly,
        group: finding.group,
      };
    };

  // Finding들의 array인 targets 내에 key와 동일한 finding을 찾아주는 헬퍼 함수
  const getSameFinding = (
    key: LocalFinding,
    targets: LocalFinding[]
  ): LocalFinding | undefined =>
    targets.find(
      value =>
        value.image === key.image &&
        JSON.stringify(value.points) === JSON.stringify(key.points)
    );

  const getAllAssetsForFinding = (
    findingShape: ShapeEnum,
    assets: ClaimAssetSchema[]
  ): ClaimAssetSchema[] => {
    const currentGroup = `finding/${findingShape}`;
    return assets.filter(asset => asset.group === currentGroup);
  };

  // 1. Get matched assets from the project for the current finding.
  // 2. Map the matched assets to the default labels.
  const getDefaultLabels = (
    targetFindingShape: ShapeEnum,
    assets: ClaimAssetSchema[]
  ): FindingLabel[] =>
    getAllAssetsForFinding(targetFindingShape, assets).map(asset => {
      return {
        name: asset.name,
        value: getDefaultLabelValue(asset),
        viewOnly: false,
      };
    });

  const clampedValue = (min: number, max: number, value: number) =>
    Math.max(min, Math.min(max, value));

  const newCurrentFrameFromMouseWheel = (
    deltaY: number,
    currentFrame: number,
    frameLimits: number[]
  ) => {
    const orderedLimits = frameLimits.sort((a, b) => a - b);
    const minFrame = orderedLimits[0] || 0;
    const maxFrame = orderedLimits[1] || 0;
    const directionFactor = deltaY > 0 ? 1 : -1;
    const increment = directionFactor * Math.ceil(Math.abs(deltaY / 25));
    const newCurrentFrame = currentFrame + increment;
    const clampedNewCurrentFrame = clampedValue(
      minFrame,
      maxFrame,
      newCurrentFrame
    );
    return clampedNewCurrentFrame;
  };

  const getDefaultLabelValue = ({ form, formAttributes }: ClaimAssetSchema) => {
    switch (form) {
      case AssetForm.SELECTOR:
        return (formAttributes as SelectorAttributes).categories
          .map(({ name }) => name)
          .reduce(
            (prev, curr) => ({
              ...prev,
              [curr]: false,
            }),
            {}
          );
      case AssetForm.TOGGLER:
        return false;
      case AssetForm.TEXTER:
      default:
        return '';
    }
  };

  // grouped findings should be checked against the following rule:
  //   (DBT only) it is desired (ideal) that for each lesion there should be findings in other corresponding views (laterality)
  //   each group should have all 4 L views or all 4 R views, e.g.: [RCC_2D, RCC_3D, RMLO_2D, RMLO_3D]
  const hasAnyUndesiredUngroupedFinding = (
    findings: Record<string, LocalFinding[]>
  ): boolean => {
    let undesiredGroupCount = 0;

    Object.keys(findings).forEach(groupName => {
      const groupFindings = findings[groupName];
      if (groupFindings?.length !== 4) {
        undesiredGroupCount += 1;
      }
    });

    return undesiredGroupCount > 0;
  };

  // (DBT only) find and return any unmatched finding labels (matching of their values)
  // 1. check within 2D findings (makes sense only there are 2 findings)
  // 2. check within 3D findings (makes sense only there are 2 findings)
  // 3. check across 2D and 3D findings only if they have some overlapping in their labels
  const getUnmatchedFindingLabels = (
    findings: LocalFinding[]
  ): ValidatedLabels => {
    const groupedFindings = groupBy<LocalFinding>(
      findings.filter(f => !f.viewOnly),
      'group'
    );

    // Convert the object to an array of key-value pairs
    const entries = Object.entries(groupedFindings);
    const unmatchedLabels = [];

    // iterate through each [group, findings] pair
    for (const [groupName, localFindings] of entries) {
      const findings3D = localFindings.filter(
        lf => lf.shape === FindingShape.MULTI_FRAME_POLYGON
      );
      const findings2D = localFindings.filter(
        lf => lf.shape !== FindingShape.MULTI_FRAME_POLYGON
      );

      // make sure two 2D/3D findings labels match
      (() => {
        [findings2D, findings3D].forEach(findingType => {
          if (findingType.length === 2) {
            const unmatchedLabel = findUnmatchedFindingLabel(
              ensure(findingType[0]),
              ensure(findingType[1])
            );
            if (unmatchedLabel)
              unmatchedLabels.push({ group: groupName, label: unmatchedLabel });
          }
        });
      })();

      // now find overlapped labels across 2D and 3D findings
      const overlappedLabels = findCommon2D3DLabels(findings2D, findings3D);
      if (overlappedLabels.length > 0) {
        // this arrangement should be sufficient as we already checked
        // the similarity of findings in within 2D and 3D
        const unmatchedLabel = findUnmatchedFindingLabel(
          ensure(findings2D[0]),
          ensure(findings3D[0])
        );
        unmatchedLabels.push({ group: groupName, label: unmatchedLabel });
      }
    }

    return unmatchedLabels;
  };

  const isFocused =
    (findingIndex: number | undefined, currentGroupName: string | undefined) =>
    (contour: FindingContour) =>
      contour.id === findingIndex || contour.group === currentGroupName;

  /**
   * This function checks if one of the two strings is a substring of the other (case insensitive).
   * The reason is:
   * - almost all 3D assets use the 2D asset's name with a m_ prefix. For example:
   *   - 3DFindingLabelName =  'm_' + 2DFindingLabelName
   * The only exception is is the label pairs `polygonType` and `multiFramePolygonType`.
   * They have the same values but don't use the m_ prefix pattern.
   * These assumptions are safe because BE manually generates projects and follows this pattern.
   * This a necessary temporary hack to sync 2D and 3D findings until we implement the ontology system.
   */
  const areLabelNamesMatched = (string1: string, string2: string) => {
    const RegEx1 = new RegExp(string1, 'i');
    const RegEx2 = new RegExp(string2, 'i');
    return RegEx1.test(string2) || RegEx2.test(string1);
  };

  const syncedLabelsWithOriginalNames = (
    masterFindingLabels: FindingLabelType[],
    findingLabels: FindingLabelType[]
  ) => {
    return findingLabels.map(findingLabel => {
      const masterFindingLabelSyncSource = masterFindingLabels.find(
        masterFindingLabel =>
          areLabelNamesMatched(masterFindingLabel.name, findingLabel.name)
      );
      if (masterFindingLabelSyncSource === undefined) return findingLabel;

      const { name, ...masterFindingLabelPropertiesToSync } =
        masterFindingLabelSyncSource;

      const syncedLabelWithOriginalName = {
        name: findingLabel.name,
        ...masterFindingLabelPropertiesToSync,
      };
      return syncedLabelWithOriginalName;
    });
  };

  const isPrimaryFrameOutOfRange = (
    primaryFrame: number | undefined,
    startFrame: number | undefined,
    endFrame: number | undefined
  ) => {
    if (
      typeof startFrame !== 'number' ||
      typeof endFrame !== 'number' ||
      typeof primaryFrame !== 'number'
    ) {
      return true;
    }

    return primaryFrame < startFrame || primaryFrame > endFrame;
  };

  const syncedFindings = (
    masterFinding: LocalFinding,
    allFindings: LocalFinding[]
  ) =>
    allFindings.map(finding => {
      if (finding.group !== masterFinding.group) return finding;

      if (finding.shape === FindingShape.MULTI_FRAME_POLYGON) {
        const { startFrame, endFrame, primaryFrame } = finding ?? {};

        if (isPrimaryFrameOutOfRange(primaryFrame, startFrame, endFrame)) {
          return finding;
        }
      }

      const masterFindingLabels = masterFinding.labels;
      if (masterFindingLabels === undefined) return finding;

      const findingLabels = finding.labels;
      if (findingLabels === undefined) return finding;

      const syncedFinding = {
        ...finding,
        labels: syncedLabelsWithOriginalNames(
          masterFindingLabels,
          findingLabels
        ),
        confirmed: true,
      };
      return syncedFinding;
    });

  const circleNumbers: { [k: string]: string } = {
    '1': '①',
    '2': '②',
    '3': '③',
    '4': '④',
    '5': '⑤',
    '6': '⑥',
    '7': '⑦',
    '8': '⑧',
  };

  const focusedContourColor = 'rgb(255, 194, 17)';
  const whiteColor = 'rgb(255, 255, 255)';

  return Object.freeze({
    addConfirmedField,
    removeConfirmedField,
    getContourFromFinding,
    getSameFinding,
    getAllAssetsForFinding,
    getDefaultLabels,
    getDefaultLabelValue,
    newCurrentFrameFromMouseWheel,
    hasAnyUndesiredUngroupedFinding: (findings: LocalFinding[]) =>
      hasAnyUndesiredUngroupedFinding(
        groupBy<LocalFinding>(
          findings.filter(f => !f.viewOnly),
          'group'
        )
      ),
    getUnmatchedFindingLabels,
    isFocused,
    syncedLabelsWithOriginalNames,
    isPrimaryFrameOutOfRange,
    syncedFindings,
    circleNumbers,
    focusedContourColor,
    whiteColor,
  });
})();

const findCommon2D3DLabels = (
  findings2D: LocalFinding[],
  findings3D: LocalFinding[]
) => {
  function findCommon2D3DLabelNames(array1: string[], array2: string[]) {
    return array1.filter(item => array2.includes(item));
  }

  // flatten [[n,n,n], [n,n]] and filter unique labels
  const labelNames2D = Array.from(
    new Set(
      findings2D.map(f => f.labels?.map(l => l.name)).flatMap(i => i || [])
    )
  );
  const labelNames3D = Array.from(
    new Set(
      findings3D.map(f => f.labels?.map(l => l.name)).flatMap(i => i || [])
    )
  );

  return findCommon2D3DLabelNames(labelNames2D, labelNames3D);
};

const findUnmatchedFindingLabel = (
  baseFinding: LocalFinding,
  targetFinding: LocalFinding
): FindingLabel | undefined => {
  // TODO: TBD
  for (const baseFindingLabel of baseFinding?.labels || []) {
    // if there is no corresponding label in the targetFinding then no need to check others values
    if (
      !targetFinding.labels?.map(l => l.name).includes(baseFindingLabel.name)
    ) {
      return baseFindingLabel;
    }

    // iterate over all sub values: value = { val1: false, val2: true, val3: false, ... }
    if (typeof baseFindingLabel.value === 'object') {
      const targetLabel = targetFinding.labels?.find(
        l => l.name === baseFindingLabel.name
      )?.value as { [k: string]: boolean };

      for (const keyOfVal of Object.keys(baseFindingLabel.value)) {
        if (targetLabel[keyOfVal] !== baseFindingLabel.value[keyOfVal]) {
          return baseFindingLabel;
        }
      }
    }

    if (
      typeof baseFindingLabel.value === 'string' ||
      typeof baseFindingLabel.value === 'boolean'
    ) {
      const targetLabelValue = targetFinding.labels?.find(
        l => l.name === baseFindingLabel.name
      )?.value as string | boolean;
      if (baseFindingLabel.value !== targetLabelValue) {
        return baseFindingLabel;
      }
    }
  }

  return undefined;
};

export default FindingUtils;
