import { useNavigate } from 'react-router-dom';

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

const NotFound = (): JSX.Element => {
  const navigate = useNavigate();

  const goToWorkspace = () => {
    navigate('/');
  };

  return (
    <Container>
      <Stack>
        <Typography variant="h4">Page Not Found</Typography>
        <Button
          variant="outlined"
          sx={{ marginTop: 3 }}
          onClick={goToWorkspace}
        >
          Go back to Workspace
        </Button>
      </Stack>
    </Container>
  );
};

const Container = styled('div', { name: 'AsideItem' })(() => ({
  width: '100%',
  height: '100vh',
  backgroundColor: 'var(--ctl-background-color)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export default NotFound;
