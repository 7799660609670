import React, { Component } from 'react';

import cornerstone from 'cornerstone-core';

import { styled } from '@mui/material/styles';

import { FrameConsumer } from '@InsightViewer/context/frame';
import { hitTestContours } from '@InsightViewer/geom/hitTestContours';
import { InsightViewerGuestProps } from '@InsightViewer/hooks/useInsightViewerSync';
import { Contour, Point } from '@InsightViewer/types';

export interface ContourHoverProps<T extends Contour>
  extends InsightViewerGuestProps {
  width: number;
  height: number;

  /** Contour 데이터를 상속받은 Annotation 데이터 */
  contours: T[];

  /**
   * Hover 기능 활성화 여부
   * HTMLElement로 입력할 경우 MouseEvent를 해당 HTMLElement를 사용해서 처리한다.
   */
  hover: boolean | HTMLElement | null;

  /**
   * 특정 Contour에 Mouse Over 되었을 때
   * focusedContour를 결정하는데 필요하다
   */
  onFocus: (contour: T | null) => void;

  className?: string;
}

interface ContourHoverState {
  polygon: Point[];
}

export class ContourHoverBase<T extends Contour> extends Component<
  ContourHoverProps<T>,
  ContourHoverState
> {
  private svg: SVGSVGElement | null = null;
  private element: HTMLElement | null = null;
  private focused: T | null = null;
  private contentWindow: Window = window;

  constructor(props: ContourHoverProps<T>) {
    super(props);

    this.state = {
      polygon: [],
    };
  }

  render(): JSX.Element {
    return (
      <>
        <FrameConsumer
          stateRef={({ contentWindow }) => (this.contentWindow = contentWindow)}
        />
        <svg
          ref={this.svgRef}
          role="figure"
          width={this.props.width}
          height={this.props.height}
          className={this.props.className}
        ></svg>
      </>
    );
  }

  svgRef = (svg: SVGSVGElement): void => {
    if (svg && this.svg && this.element) {
      this.deactivateInitialEvents();

      if (this.canActivate(this.props)) {
        this.svg = svg;
        this.element = this.getElement(this.props);
        this.activateInitialEvents();
      }
    }

    this.svg = svg;
  };

  componentDidMount(): void {
    if (!this.svg) throw new Error('<svg> is not initialized');

    if (this.canActivate(this.props)) {
      this.element = this.getElement(this.props);
      this.activateInitialEvents();
    }
  }

  componentDidUpdate(prevProps: Readonly<ContourHoverProps<T>>): void {
    if (prevProps.hover !== this.props.hover) {
      if (this.element) this.deactivateInitialEvents();

      if (this.canActivate(this.props)) {
        this.element = this.getElement(this.props);
        this.activateInitialEvents();
      }
    }
  }

  componentWillUnmount(): void {
    if (this.element) this.deactivateInitialEvents();
  }

  getElement = ({ hover }: Readonly<ContourHoverProps<T>>): HTMLElement => {
    return hover instanceof window.HTMLElement
      ? (hover as HTMLElement)
      : (this.svg as unknown as HTMLElement);
  };

  canActivate = ({ hover }: Readonly<ContourHoverProps<T>>): boolean => {
    return hover instanceof window.HTMLElement || hover === true;
  };

  // ---------------------------------------------
  // initial events
  // ---------------------------------------------
  activateInitialEvents = (): void => {
    if (this.element)
      this.element.addEventListener('mousemove', this.onMouseMoveToFindFocus);
  };

  deactivateInitialEvents = (): void => {
    if (this.element)
      this.element.removeEventListener(
        'mousemove',
        this.onMouseMoveToFindFocus
      );
  };

  onMouseMoveToFindFocus = (event: MouseEvent): void => {
    this.findFocus(event.pageX, event.pageY);
  };

  findFocus = (pageX: number, pageY: number): void => {
    if (
      !this.props.contours ||
      this.props.contours.length === 0 ||
      !this.props.cornerstoneRenderData
    )
      return;

    const { element } = this.props.cornerstoneRenderData;

    const { x, y } = cornerstone.pageToPixel(element, pageX, pageY);

    this.focused = hitTestContours<T>(this.props.contours, [x, y]);

    this.props.onFocus(this.focused);
  };
}

/* eslint-disable @typescript-eslint/no-explicit-any */
export const ContourHover: new <T extends Contour>() => ContourHoverBase<T> =
  styled(ContourHoverBase)`
    position: absolute;
    top: 0;
    left: 0;
  ` as any;
