import { MouseEventHandler, KeyboardEventHandler, useMemo } from 'react';

import { SelectorAttributes } from '@lunit-io/ctl-api-interface';
import { useRecoilState } from 'recoil';

import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { styled } from '@mui/material/styles';

import { withErrorShake } from 'src/components/withErrorShake';
import useSetAliasToFinding from 'src/hooks/tasks/useSetAliasToFinding';
import { SetLabelFunction } from 'src/hooks/tasks/useSetFindingLabel';
import { Label, SelectorAsset, SelectorLabel } from 'src/interfaces';
import { errorLabelNameState } from 'src/states/label';

import AssetPanel from './AssetPanel';

type FindingTypeRecord = { [k: string]: boolean };

export interface SelectorProps {
  asset: SelectorAsset;
  labels: Label[];
  labelIndex: number;
  isLabelImmutable: boolean;
  onChangeLabel: SetLabelFunction;
  testId?: string;
}

const Selector = ({
  asset,
  labels,
  labelIndex,
  isLabelImmutable,
  onChangeLabel,
  testId,
}: SelectorProps): JSX.Element => {
  const setAliasToFinding = useSetAliasToFinding();
  const [errorLabel, setErrorLabel] = useRecoilState(errorLabelNameState);

  const formAttributes = asset.formAttributes as SelectorAttributes;

  const labelValue = useMemo(() => {
    const currentLabel = labels[labelIndex] as SelectorLabel;
    const initialLabelValue = formAttributes.categories.reduce(
      (acc: FindingTypeRecord, cur) => ({
        ...acc,
        [cur.name]: false,
      }),
      {}
    );

    return currentLabel?.value || initialLabelValue;
  }, [labels, labelIndex, formAttributes.categories]);

  const setLabelValue = (newSelection: string) => {
    const value = { ...labelValue };
    const oldSelections: string[] = [];

    for (const [k, v] of Object.entries(labelValue)) {
      if (v) oldSelections.push(k);
    }

    // 1. Multiple keys can be true
    if (formAttributes.allowMultiSelect) {
      if (formAttributes.allowEmpty) {
        value[newSelection] = !oldSelections.includes(newSelection);
      } else {
        // For leaving last value
        if (oldSelections.length !== 1 || oldSelections[0] !== newSelection) {
          value[newSelection] = !value[newSelection];
        }
      }
    }
    // 2. Only one key can be true and should be true
    else if (!formAttributes.allowEmpty) {
      for (const oldSelection of oldSelections) {
        value[oldSelection] = false;
      }
      value[newSelection] = true;
    }
    // 3. Only one key can be true and can be false
    else {
      for (const oldSelection of oldSelections) {
        if (oldSelection !== newSelection) {
          value[oldSelection] = false;
        }
      }
      value[newSelection] = !value[newSelection];
    }

    const label: SelectorLabel = {
      name: asset.name,
      value,
      viewOnly: false,
    };

    onChangeLabel(label, labelIndex);
    setAliasToFinding(label);
  };

  const getClickHandler =
    (selectedValue: string): MouseEventHandler<HTMLDivElement> =>
    () => {
      setLabelValue(selectedValue);
    };

  /**
   * For preventing enable/disable with spaceBar for saving the job.
   * @param event
   * @see https://lunit.atlassian.net/browse/AISS-263
   */
  const handleKeyUp: KeyboardEventHandler<HTMLDivElement> = event => {
    event.preventDefault();
  };

  return (
    <ShakableAssetPanel
      shake={errorLabel === asset.name}
      onShakeFinish={() => {
        setErrorLabel(undefined);
      }}
      assetText={asset.text}
      assetAllowEmpty={formAttributes.allowEmpty}
      data-testid={testId}
    >
      {formAttributes.categories.map(({ text, name }) => (
        <StyledListItem
          key={text}
          selected={labelValue[name] || false}
          onClick={getClickHandler(name)}
          onKeyUp={handleKeyUp}
          disabled={isLabelImmutable}
          data-testid={`${
            asset.group === 'decision' ? 'decision' : 'finding'
          }-selector-label`}
        >
          <StyledListItemText primary={text} />
        </StyledListItem>
      ))}
    </ShakableAssetPanel>
  );
};

export default Selector;

const StyledListItem = styled(ListItemButton)({
  flexGrow: 'initial',
  boxSizing: 'content-box',
  width: 'auto',
  height: '1.25rem',
  padding: '4px 8px',
  margin: 2,
  backgroundColor: 'var(--ctl-background-color-lighter)',
  borderRadius: 'var(--ctl-border-radius)',
  '&:hover': {
    backgroundColor: 'var(--ctl-background-color-lightest)',
  },
  '&.Mui-selected': {
    backgroundColor: 'var(--ctl-brand-color)',
    '&:hover': {
      backgroundColor: 'var(--ctl-brand-color-light)',
    },
  },
});

const StyledListItemText = styled(ListItemText)({
  '.MuiListItemText-primary': {
    fontSize: '.875rem',
  },
});

const ShakableAssetPanel = withErrorShake(AssetPanel);
