import { DetailedHTMLProps, HTMLAttributes } from 'react';

import { styled } from '@mui/material/styles';

export type FormPageContainerProps = DetailedHTMLProps<
  HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

export function FormPageContainerBase({
  children,
  ...props
}: FormPageContainerProps): JSX.Element {
  return (
    <div {...props}>
      <div>{children}</div>
    </div>
  );
}

export const FormPageContainer = styled(FormPageContainerBase)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  background-image: url(./assets/background.jpg);
  background-size: cover;

  > div {
    width: 582px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #232d40;
    padding-top: 40px;
    padding-bottom: 60px;

    display: flex;
    align-content: center;
    justify-content: center;
    flex-flow: column;

    h1 {
      text-align: center;

      svg {
        width: 11em;
        max-width: 100%;
        height: auto;
      }
    }

    form {
      align-self: center;

      margin-top: 30px;

      width: 415px;

      display: flex;
      flex-flow: column;

      > :not(:last-child) {
        margin-bottom: 10px;
      }

      button {
        width: 100%;
      }
    }

    footer {
      display: block;
      opacity: 1;
      text-align: center;
      margin: -0.5rem auto;

      p {
        margin: 0.5rem auto;
      }
    }
  }
`;
