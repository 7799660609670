import { styled } from '@mui/material/styles';

import { Shortcut } from 'src/interfaces/shortcut';
import { ShortcutUtils } from 'src/utils/shortcuts';

interface Props {
  shortcut: Shortcut;
}

const ShortcutItem = ({ shortcut }: Props): JSX.Element => {
  return (
    <Container key={shortcut}>
      <KeyboardIcon>
        <div>{ShortcutUtils.getKeyEntity(shortcut)}</div>
      </KeyboardIcon>
      <Description>{ShortcutUtils.getDescription(shortcut)}</Description>
    </Container>
  );
};

export default ShortcutItem;

const Container = styled('div')`
  flex: 1;
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const Description = styled('span')`
  color: var(--ctl-color);
`;

const KeyboardIcon = styled('span')`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 1.25rem;
  height: 1.25rem;
  border: 2px solid rgba(255, 255, 255, 0.4);
  background-color: rgba(255, 255, 255, 0.2);
  color: rgba(255, 255, 255, 0.8);
  font-size: 0.75rem;
  border-radius: 2px;
  text-transform: capitalize;
  > div {
    padding: 0.125rem 0.25rem;
  }
`;
