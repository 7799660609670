import { Hosts } from '.';

const hosts: Hosts = {
  CTL_AUTHENTICATOR_SERVER: 'https://auth.ctl.research.dev.lunit.io',
  CTL_DATA_SERVER: 'https://data.ctl.research.dev.lunit.io',
  CTL_IMAGE_SERVER: 'https://image.ctl.research.dev.lunit.io',
  CTL_ELASTIC_SEARCH: 'https://lunit.es.asia-northeast1.gcp.cloud.es.io:9243',
};

export default hosts;
