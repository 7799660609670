import { useRecoilValue } from 'recoil';

import DialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/material/styles';

import StyledDialog from 'src/components/modals/StyledDialog';
import StyledDialogContent from 'src/components/modals/StyledDialogContent';
import shortcutState from 'src/states/shortcut';

import ShortcutItem from './ShortcutItem';

interface Props {
  visibility: boolean;
  onClose: () => void;
}

const ShortcutsModal = ({ visibility, onClose }: Props): JSX.Element => {
  const shortcutDefList = useRecoilValue(shortcutState.executableDefinitions);

  return (
    <StyledDialog open={visibility} onClose={onClose}>
      <DialogTitle id="alert-dialog-title">Shortcuts</DialogTitle>
      <StyledDialogContent>
        <Container>
          {shortcutDefList.map(({ shortcut }) => (
            <Item key={shortcut}>
              <ShortcutItem shortcut={shortcut} />
            </Item>
          ))}
        </Container>
      </StyledDialogContent>
    </StyledDialog>
  );
};

export default ShortcutsModal;

const Container = styled('div')`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  min-width: 480px;
  max-width: 600px;
  margin: 1rem -1.5rem;
`;

const Item = styled('div')`
  display: flex;
  width: 50%;
  padding: 0.3rem 1.5rem;
  box-sizing: border-box;
`;
