import { ReactNode } from 'react';

import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import RowButtonGroup from 'src/components/forms/RowButtonGroup';

interface Props {
  title?: string;
  subTitle1?: string;
  subTitle2?: string;
  children?: ReactNode;
}

const ErrorPanel = ({
  title = 'Error',
  subTitle1 = 'Something went to wrong',
  subTitle2,
  children,
}: Props): JSX.Element => {
  return (
    <Container role="alert" data-test-id="error-panel">
      <StyledPaper>
        <Typography variant="h5" component="h1">
          {title}
        </Typography>
        <Description>
          <Typography variant="body1" color="textSecondary" component="div">
            {subTitle1}
          </Typography>
          {subTitle2 && (
            <Typography variant="body1" color="textSecondary" component="div">
              {subTitle2}
            </Typography>
          )}
        </Description>
        {children && <RowButtonGroup>{children}</RowButtonGroup>}
      </StyledPaper>
    </Container>
  );
};

export default ErrorPanel;

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Description = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const StyledPaper = styled(Paper)`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 2rem;
  max-width: 60rem;
  background-color: transparent;
  background-image: none;
`;
