import { ClaimAssetIdentifierSchema } from '@lunit-io/ctl-api-interface';

import { Label } from 'src/interfaces';
import AssetUtils from 'src/utils/asset';

export type AssetValidatorKey = 'ifSet' | 'oneOf';

export type AssetValidatorFunc = (
  identifier: ClaimAssetIdentifierSchema | ClaimAssetIdentifierSchema[],
  labels: Label[]
) => boolean;

const assetValidatorFuncMap: Record<AssetValidatorKey, AssetValidatorFunc> = {
  ifSet: (identifier, labels) => {
    if (Array.isArray(identifier)) {
      throw new Error('"ifSet" validation has wrong definition.');
    }

    const targetLabel = labels.find(label => label.name === identifier.name);

    if (!targetLabel || !identifier.selectedValues) {
      return false;
    }

    return identifier.selectedValues.every(
      AssetUtils.isThisLabelSelected(targetLabel)
    );
  },
  oneOf: (identifiers, labels) => {
    if (!Array.isArray(identifiers)) {
      throw new Error('"oneOf" validation has wrong definition.');
    }

    const message = `One of ${identifiers
      .map(({ text, name }) => `"${text || name}"`)
      .join(', ')} should be selected.`;

    const targetLabels = labels.filter(l =>
      identifiers.find(i => i.name === l.name)
    );

    if (!targetLabels.length) {
      throw new Error(message);
    }

    const isSomeSelected = identifiers.some(
      ({ name }: ClaimAssetIdentifierSchema) => {
        const targetLabel = targetLabels.find(l => l.name === name);
        if (targetLabel === undefined) {
          return false;
        }
        if (typeof targetLabel.value === 'boolean') {
          return targetLabel.value;
        }
        if (typeof targetLabel.value === 'object') {
          return Object.values(targetLabel.value).includes(true);
        }
        return false;
      }
    );

    if (!isSomeSelected) {
      throw new Error(message);
    }

    return true;
  },
};

export default assetValidatorFuncMap;
