import { useState, useEffect } from 'react';

import { useIdleTimer } from 'react-idle-timer';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import useConfirmModal from 'src/hooks/useConfirmModal';
import { confirmModalState } from 'src/states/confirmModal';
import { DEFAULT_CONFIRM_MODAL_STATE } from 'src/states/defaults';
import { jobState } from 'src/states/job';
import jobIdList from 'src/states/jobIdList';

enum IdleState {
  ACTIVE = 'Active',
  IDLE = 'Idle',
  PROMPTED = 'Prompted',
}

type UseTimeTrackerReturn = {
  timeSpent: number;
  idleState: IdleState;
};

export const useTimeTracker = (): UseTimeTrackerReturn => {
  const [timeSpent, setTimeSpent] = useRecoilState(jobState.timeSpent);
  const currentJobId = useRecoilValue(jobIdList.currentJobId);
  const setConfirmModalState = useSetRecoilState(confirmModalState);
  const [idleState, setIdleState] = useState<IdleState>(IdleState.ACTIVE);
  const { getConfirmation } = useConfirmModal();

  const onActive = () => {
    // If the modal is shown in one tab but the user interacts with the other tab, message the original tab to close the modal
    if (!isLastActiveTab()) {
      message(IdleState.ACTIVE);
    }

    setIdleState(IdleState.ACTIVE);
  };

  const onPrompt = async () => {
    setIdleState(IdleState.PROMPTED);
    // For CPC jobs, only show the modal in the last active tab
    if (isLastActiveTab()) {
      await getConfirmation({
        title: 'Time tracking is paused',
        description: 'Resume annotations to continue tracking time',
        confirmButtonText: 'Resume',
        showCancelButton: false,
      });

      activate();
      message(IdleState.ACTIVE);
    }
  };

  const onMessage = (msg: IdleState) => {
    // If the modal is shown in the current tab but the user interacts with the other tab, receive this message and close the modal
    setIdleState(msg);
    setConfirmModalState(DEFAULT_CONFIRM_MODAL_STATE);
  };

  const { reset, activate, message, isLastActiveTab } = useIdleTimer({
    onIdle: () => setIdleState(IdleState.IDLE),
    onActive,
    onPrompt,
    onMessage,
    startOnMount: false,
    timeout: 60_000,
    crossTab: true,
    leaderElection: true,
    promptBeforeIdle: 200,
    syncTimers: 200,
    throttle: 1000,
    eventsThrottle: 1000,
  });

  useEffect(() => {
    if (currentJobId) {
      reset();
      setTimeSpent(0);
    }
  }, [currentJobId, reset, setTimeSpent]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (idleState === IdleState.ACTIVE) {
        setTimeSpent(prev => prev + 1000);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [idleState, setTimeSpent]);

  return { timeSpent, idleState };
};
