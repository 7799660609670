import { atom, atomFamily, selector } from 'recoil';

import { LocalFinding, Label, FindingShape } from 'src/interfaces';
import { jobState } from 'src/states/job';
import FindingUtils from 'src/utils/finding';
import { SIDEBAR_WIDTH, localStorageEffect } from 'src/utils/localStore';

import { operationModeState } from './operationMode';
import { projectState } from './project';

const findingIndex = atom<number | undefined>({
  key: 'task/findingIndex',
  default: undefined,
});

const showFindings = atom<boolean>({
  key: 'task/showFindings',
  default: true,
});

const showDBT3D = atom<boolean>({
  key: 'task/showDBT3D',
  default: false,
});

const localDecisionLabels = atom<Label[]>({
  key: 'task/localDecisionLabels',
  default: [],
});

const localFindings = atom<LocalFinding[]>({
  key: 'task/localFindings',
  default: [],
});

const currentFrame = atomFamily<number, string>({
  key: 'task/currentFrame',
  default: 0,
});

const assets = selector({
  key: 'task/assets',
  get: ({ get }) => {
    const {
      claim: { assets },
    } = get(projectState.current);
    return assets;
  },
});

const decisionAssets = selector({
  key: 'task/decisionAssets',
  get: ({ get }) => {
    const allAssets = get(assets);
    return allAssets.filter(asset => asset.group === 'decision');
  },
});

const decisionLabels = selector({
  key: 'task/decisionLabels',
  get: ({ get }) => {
    const job = get(jobState.current);
    const currentMode = get(operationModeState.current);
    const local = get(localDecisionLabels);

    if (currentMode.isEditable) {
      return local;
    }

    return job.labels[currentMode.name] || [];
  },
});

const hasDecisionCategories = selector({
  key: 'task/hasDecisionCategories',
  get: ({ get }) => {
    const project = get(projectState.current);
    return !!project.claim.categories?.length;
  },
});

const decisionCategories = selector({
  key: 'task/decisionCategories',
  get: ({ get }) => {
    const project = get(projectState.current);
    const { categories } = project.claim;

    if (!categories) return [];

    // top level categories without any parents
    const parentCategories = categories.filter(
      category => category.parent === null
    );

    // child categories to be nested under parent categories
    const childCategories = categories.filter(
      category => category.parent !== null
    );

    // group categories in a tree structure including all assets
    const groupedCategoriesWithAllAssets = parentCategories.map(
      parentCategory => {
        const childCategoriesForThisParent = childCategories.filter(
          ({ parent }) => parent === parentCategory.id
        );
        const childCategoriesWithAllAssets = childCategoriesForThisParent.map(
          childCategory => {
            const allAssetsForThisCategory = [];

            const parentAssetsForThisCategory = get(decisionAssets).filter(
              asset => {
                const isParentAsset = asset.category === childCategory.id;
                return isParentAsset;
              }
            );
            allAssetsForThisCategory.push(...parentAssetsForThisCategory);

            parentAssetsForThisCategory.forEach(parentAsset => {
              const childAssetsForThisCategory = get(decisionAssets).filter(
                decisionAsset => decisionAsset?.parent?.id === parentAsset.id
              );
              allAssetsForThisCategory.push(...childAssetsForThisCategory);
            });
            return {
              ...childCategory,
              assets: [...allAssetsForThisCategory],
            };
          }
        );

        return { ...parentCategory, children: childCategoriesWithAllAssets };
      }
    );

    return groupedCategoriesWithAllAssets;
  },
});

const findings = selector<LocalFinding[]>({
  key: 'task/findings',
  get: ({ get }) => {
    const job = get(jobState.current);
    const currentMode = get(operationModeState.current);
    const local = get(localFindings);

    if (currentMode.isEditable) {
      return local;
    }

    return job.findings[currentMode.name] || [];
  },
});

const currentFinding = selector({
  key: 'task/currentFinding',
  get: ({ get }) => {
    const index = get(findingIndex);
    return get(findings).find(finding => finding.index === index);
  },
});

const isMultiFramePolygon = selector<boolean>({
  key: 'task/isMultiFramePolygon',
  get: ({ get }) =>
    get(currentFinding)?.shape === FindingShape.MULTI_FRAME_POLYGON,
});

const currentGroupName = selector({
  key: 'task/currentGroupName',
  get: ({ get }) => get(currentFinding)?.group,
});

const findingsInCurrentGroup = selector({
  key: 'task/findingsInCurrentGroup',
  get: ({ get }) => {
    const currentGroup = get(currentGroupName);
    return get(findings).filter(finding => finding.group === currentGroup);
  },
});

const currentFindingAssets = selector({
  key: 'task/currentFindingAssets',
  get: ({ get }) => {
    const finding = get(currentFinding);
    const project = get(projectState.current);

    if (finding === undefined) {
      return [];
    }

    return FindingUtils.getAllAssetsForFinding(
      finding.shape,
      project.claim.assets
    );
  },
});

const fullscreenIndex = atom<number | undefined>({
  key: 'task/fullscreenIndex',
  default: undefined,
});

export const DEFAULT_SIDEBAR_WIDTH = 270;

const sidebarWidth = atom({
  key: 'task/sidebarWidth',
  default: Number(localStorage.getItem(SIDEBAR_WIDTH) ?? DEFAULT_SIDEBAR_WIDTH),
  effects: [localStorageEffect(SIDEBAR_WIDTH)],
});

export const taskState = Object.freeze({
  findingIndex,
  showFindings,
  showDBT3D,
  localDecisionLabels,
  localFindings,
  currentFrame,
  assets,
  decisionAssets,
  decisionLabels,
  hasDecisionCategories,
  decisionCategories,
  findings,
  currentFinding,
  isMultiFramePolygon,
  currentGroupName,
  findingsInCurrentGroup,
  currentFindingAssets,
  fullscreenIndex,
  sidebarWidth,
});
