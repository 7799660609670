import { Suspense } from 'react';

import { ErrorBoundary } from 'react-error-boundary';
import { Navigate, Outlet } from 'react-router-dom';

import { GlobalErrorFallback } from './components/ErrorFallback/GlobalErrorFallback';
import Loading from './components/loading';
import { getAccessToken } from './http';

const PrivateRoute = (): JSX.Element => {
  if (getAccessToken())
    return (
      <ErrorBoundary key="global" FallbackComponent={GlobalErrorFallback}>
        <Suspense fallback={<Loading />}>
          <Outlet />
        </Suspense>
      </ErrorBoundary>
    );

  return <Navigate to="/login" />;
};

export default PrivateRoute;
