import { StrictMode } from 'react';

import cornerstone from 'cornerstone-core';
import cornerstoneWADOImageLoader from 'cornerstone-wado-image-loader';
import cornerstoneWebImageLoader from 'cornerstone-web-image-loader';
import dicomParser from 'dicom-parser';
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from 'react-router-dom';

import Database from 'src/pages/database';
import LoginPage from 'src/pages/login';
import WorkspacePage from 'src/pages/workspace';

import { CombinedProviders } from './CombinedProvider';
import PrivateRoute from './PrivateRoute';
import NotFound from './pages/404';
import ComponentsPage from './pages/components';
import IconsPage from './pages/icons';

import './style.scss';

cornerstoneWADOImageLoader.external.dicomParser = dicomParser;
cornerstoneWADOImageLoader.external.cornerstone = cornerstone;
cornerstoneWebImageLoader.external.cornerstone = cornerstone;

const router = createBrowserRouter([
  { path: '/', element: <Navigate replace to="/login" /> },
  { path: '/login', element: <LoginPage /> },
  { path: '/database', element: <Database /> },
  {
    path: '/',
    element: <PrivateRoute />,
    children: [
      { path: 'workspace', element: <WorkspacePage /> },
      { path: 'prior', element: <WorkspacePage /> },
    ],
  },
  { path: '/components', element: <ComponentsPage /> },
  { path: '/icons', element: <IconsPage /> },
  { path: '/*', element: <NotFound /> },
]);

export function App(): JSX.Element {
  return (
    <StrictMode>
      <CombinedProviders>
        <RouterProvider router={router} />
      </CombinedProviders>
    </StrictMode>
  );
}
