import { useLayoutEffect, useState, useRef, useCallback } from 'react';

import Draggable, { DraggableEventHandler } from 'react-draggable';
import {
  useRecoilState,
  useSetRecoilState,
  useRecoilValue_TRANSITION_SUPPORT_UNSTABLE,
  useRecoilValue,
} from 'recoil';

import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

import DisabledIssueItem from 'src/components/Issue/DisabledIssueItem';
import EditableIssueItem from 'src/components/Issue/EditableIssueItem';
import useSetShowDBT3DImages from 'src/hooks/tasks/useSetShowDBT3DImages';
import useAlert from 'src/hooks/useAlert';
import useErrorHandler from 'src/hooks/useErrorHandler';
import { Offset } from 'src/interfaces';
import { createUpdate as createUpdateService } from 'src/services/issue';
import imageState from 'src/states/image';
import issuesState, { useRefreshIssues } from 'src/states/issues';
import { projectState } from 'src/states/project';
import { taskState } from 'src/states/task';
import IssueUtils from 'src/utils/issue';
import { getOffsetFromFinding } from 'src/utils/panel';

const IssueThreadPanel = (): JSX.Element | null => {
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [offset, setOffset] = useState<Offset | undefined>();

  const [activatedId, setActivatedId] = useRecoilState(issuesState.activatedId);
  const [activatedUpdateId, setActivatedUpdateId] = useRecoilState(
    issuesState.activatedUpdateId
  );
  const setFindingIndex = useSetRecoilState(taskState.findingIndex);
  const isDBTProject = useRecoilValue(projectState.isDBT);
  const setShowDBT3D = useSetShowDBT3DImages();
  const viewTypes = useRecoilValue(imageState.viewTypes);
  const [fullscreenViewIndex, setFullscreenViewIndex] = useRecoilState(
    taskState.fullscreenIndex
  );

  const createUpdate = useErrorHandler(createUpdateService);
  const { open: openAlert } = useAlert();

  const refreshIssues = useRefreshIssues();

  const issue = useRecoilValue_TRANSITION_SUPPORT_UNSTABLE(
    issuesState.selected
  );

  const handleDragStop: DraggableEventHandler = (event, data) => {
    setOffset({ x: data.x, y: data.y });
  };

  const handleClose = useCallback(() => {
    setActivatedId(undefined);
    setActivatedUpdateId(undefined);
    setOffset(undefined);
  }, [setActivatedId, setActivatedUpdateId]);

  const handleClickSend = async () => {
    if (!inputRef.current?.value || !issue) return;
    try {
      await createUpdate({
        issueId: issue.id,
        update: inputRef.current?.value,
      });
      inputRef.current.value = '';
      refreshIssues();
    } catch (error) {
      openAlert({
        type: 'error',
        message: `Failed to create a new comment: ${(error as Error).message}`,
      });
    }
  };

  /**
   * Ensures the clicked `issue` is always visible
   */
  useLayoutEffect(() => {
    if (issue === undefined) return;
    setFindingIndex(undefined);

    const currentIssueViewType = viewTypes.find(
      view => view.name === issue.view
    );
    if (!currentIssueViewType) return;
    const { type } = currentIssueViewType;

    if (isDBTProject) {
      setShowDBT3D(type === 'multiple' ? true : false);
    }

    if (fullscreenViewIndex === undefined) return;

    const selectedDBTViews = viewTypes.filter(view => view.type === type);
    const currentViews = isDBTProject ? selectedDBTViews : viewTypes;
    const currentIssueViewIndex = currentViews.findIndex(
      activeView => activeView.name === issue.view
    );
    setFullscreenViewIndex(currentIssueViewIndex);
  }, [
    fullscreenViewIndex,
    isDBTProject,
    issue,
    setFindingIndex,
    setFullscreenViewIndex,
    setShowDBT3D,
    viewTypes,
  ]);

  /**
   * use useLayoutEffect for fast null assertion.
   * If use useEffect, contents will be change first before panel disappear.
   */
  useLayoutEffect(() => {
    /**
     * favor requestAnimationFrame instead of setTimeout
     * https://stackoverflow.com/questions/43379640/requestanimationframe-loop-not-correct-fps/43381828#43381828
     * https://developer.mozilla.org/en-US/docs/Web/API/window/requestAnimationFrame
     */
    // TODO: Refactor to reduce repeated code - this helper function is also used in `FindingLabelPanel`

    if (!activatedId || !issue || issue.idx < 0) {
      setOffset(undefined);
      return;
    }

    const setOffsetWhenIssueIsVisible = (targetIndex: number | undefined) => {
      const currentTime = new Date().getTime();
      const endTime = currentTime + 5 * 1000;
      const checkExist = setInterval(function () {
        window.requestAnimationFrame(() => {
          const element = document.querySelector<SVGPolygonElement>(
            `svg > g[data-id="${targetIndex}"][data-focused="true"]`
          );
          if (element) {
            setOffset(
              getOffsetFromFinding(
                containerRef.current,
                element.getBoundingClientRect()
              )
            );
            clearInterval(checkExist);
            return;
          }
          if (endTime < new Date().getTime()) {
            clearInterval(checkExist);
          }
          return;
        });
      }, 100);
    };
    setOffsetWhenIssueIsVisible(issue?.idx + 1);
  }, [activatedId, issue]);

  if (!issue) return null;

  const initialUpdate = issue.updates.find(({ type }) => type === 'INITIAL');
  const restUpdates = issue.updates.filter(({ type }) => type !== 'INITIAL');

  return initialUpdate ? (
    <Draggable
      position={offset}
      onStop={handleDragStop}
      nodeRef={containerRef}
      bounds="html"
      handle=".draggable-handle"
      key={issue.id}
    >
      <Container
        ref={containerRef}
        style={{ visibility: offset ? 'visible' : 'hidden' }}
      >
        <Handle className="draggable-handle">
          <div>Issue #{issue.idx + 1}</div>
          <div>
            <Tooltip title="Close" placement="top">
              <IconButton
                aria-label="close"
                size="small"
                color="error"
                onClick={handleClose}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>
          </div>
        </Handle>
        <Content>
          <div
            style={{
              backgroundColor: 'var(--ctl-background-color)',
            }}
          >
            <EditableIssueItem
              type={initialUpdate.type}
              userName={issue.userName}
              isReviewer
              text={initialUpdate?.text || ''}
              createdAt={issue.createdAt}
              issueId={issue.id}
              updateId={initialUpdate?.id || ''}
              userId={issue.userId}
              closed={issue.closed}
              isFirstUpdate
              activated={activatedUpdateId === initialUpdate?.id}
            />
            {restUpdates.map(
              ({ type, text, id, userName, userId, createdAt, isReviewer }) => (
                <div
                  style={{
                    borderTop: '1px solid var(--ctl-background-color-lighter)',
                  }}
                  key={id}
                >
                  {IssueUtils.getDisabledType({ type }) ? (
                    <DisabledIssueItem
                      createdAt={createdAt}
                      type={type}
                      userName={userName}
                      activated={activatedUpdateId === id}
                    />
                  ) : (
                    <EditableIssueItem
                      type={type}
                      userName={userName}
                      isReviewer={isReviewer}
                      text={text}
                      createdAt={createdAt}
                      issueId={issue.id}
                      updateId={id}
                      userId={userId}
                      activated={activatedUpdateId === id}
                    />
                  )}
                </div>
              )
            )}

            <div
              style={{
                borderTop: '1px solid var(--ctl-background-color-lighter)',
                padding: '0.8rem',
              }}
            >
              <TextField
                placeholder="Write a comment..."
                variant="outlined"
                fullWidth
                multiline
                size="small"
                inputRef={inputRef}
              />
              <Button
                size="small"
                fullWidth
                variant="contained"
                style={{ marginTop: '.5rem' }}
                onClick={handleClickSend}
              >
                Add a comment
              </Button>
            </div>
          </div>
        </Content>
      </Container>
    </Draggable>
  ) : null;
};

export default IssueThreadPanel;

const Container = styled('div')`
  box-sizing: border-box;
  position: fixed;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  width: 20rem;
  max-height: 50vh;
  background-color: var(--ctl-background-color-light);
  border: 1px solid var(--ctl-background-color-lightest);
  border-radius: 0.5rem;
  box-shadow: 0 0.25rem 1rem 0 rgba(0, 0, 0, 0.6);
`;

const Handle = styled('div')`
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.25rem 0.4rem 0.25rem 0.9rem;
  color: var(--ctl-color);
  background-color: var(--ctl-background-color-dark);
  border-radius: 0.5rem 0.5rem 0 0;
  cursor: move;
`;

const Content = styled('div')`
  box-sizing: border-box;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
`;
