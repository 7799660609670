import { SVGProps } from 'react';

import { styled } from '@mui/material/styles';

interface Props extends SVGProps<SVGGElement> {
  activated?: boolean;
  label: string;
}

export const ISSUE_ICON_WIDTH = 20;

const IssueIconBase = ({
  label,
  activated,
  ref,
  ...gProps
}: Props): JSX.Element => {
  return (
    <G {...gProps}>
      <circle
        cx={ISSUE_ICON_WIDTH / 2}
        cy={ISSUE_ICON_WIDTH / 2}
        r={ISSUE_ICON_WIDTH / 2}
        fill="#fff"
        id="issue-icon-base-line"
      />
      <circle
        cx={ISSUE_ICON_WIDTH / 2}
        cy={ISSUE_ICON_WIDTH / 2}
        r={8.5}
        id="issue-icon-base-circle"
        fill={activated ? 'var(--ctl-brand-color)' : '#000'}
      />
      {label && (
        <text x={ISSUE_ICON_WIDTH / 2} y={ISSUE_ICON_WIDTH / 2 + 3.5}>
          {label}
        </text>
      )}
    </G>
  );
};

export default IssueIconBase;

const G = styled('g')`
  color: white;

  text {
    text-anchor: middle;
    font-size: 11px;
    font-weight: bold;

    &:last-child {
      fill: currentColor;
    }
  }

  > path {
    fill: currentColor;

    &[data-border] {
      stroke-width: 4px;
      stroke: #000000;
    }
  }

  &[data-focused] #issue-icon-base-circle {
    fill: var(--ctl-brand-color);
  }
`;
