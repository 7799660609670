import { useCallback } from 'react';

import { useNavigate } from 'react-router-dom';
import { useResetRecoilState, useSetRecoilState } from 'recoil';

import { CPCBroadcastChannel } from 'src/hooks/cpc/useSyncCPCState';
import { logout as logoutAPI } from 'src/services/me';
import authState from 'src/states/auth';
import jobIdListState from 'src/states/jobIdList';
import { thisCPCWindow } from 'src/utils/cpc';

type Return = () => void;

const useLogout = (): Return => {
  const setMe = useSetRecoilState(authState.me);
  const resetCurrentJobId = useResetRecoilState(jobIdListState.currentJobId);

  const navigate = useNavigate();

  const logout = useCallback(async () => {
    resetCurrentJobId();
    setMe(logoutAPI());
    navigate('/login');

    CPCBroadcastChannel.postMessage({
      type: 'logout',
      origin: thisCPCWindow.name,
    });
  }, [navigate, resetCurrentJobId, setMe]);

  return logout;
};

export default useLogout;
