import { Me } from 'src/interfaces/me';
import { getDefaultProject, OperationMode } from 'src/interfaces/project';
import { AlertState, ConfirmModalState } from 'src/interfaces/ui';

export const DEFAULT_ME: Me = {
  status: 'UNSIGNED',
};

export const DEFAULT_ALERT_STATE: AlertState = {
  visibility: false,
  message: '',
  type: 'error',
};

export const DEFAULT_CONFIRM_MODAL_STATE: ConfirmModalState = {
  visibility: false,
  title: '',
  description: '',
  confirmButtonText: '',
  showCancelButton: true,
};

export const DEFAULT_OPERATION_MODE: OperationMode = {
  name: '',
  text: '',
  isEditable: false,
};

export const DEFAULT_PROJECT = getDefaultProject();
