export const dashStroke = `
@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}
stroke-dasharray: 10, 10;
stroke-dashoffset: 1000;
animation: 10s linear infinite;
animation-name: dash;
`;
