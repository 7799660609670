import { Suspense, useState } from 'react';

import { useRecoilValue } from 'recoil';

import Add from '@mui/icons-material/Add';
import ListIcon from '@mui/icons-material/List';
import PowerSettingsNew from '@mui/icons-material/PowerSettingsNew';
import ButtonBase from '@mui/material/ButtonBase';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

import AsideItem from 'src/components/aside/AsideItem';
import AddReportModal from 'src/components/modals/AddReportModal';
import ReportListModal from 'src/components/modals/ReportListModal';
import ButtonList from 'src/components/task/ButtonList';
import ButtonListItem from 'src/components/task/ButtonListItem';
import useLogout from 'src/hooks/authentications/useLogout';
import useConfirmModal from 'src/hooks/useConfirmModal';
import authState from 'src/states/auth';
import { jobState } from 'src/states/job';
import { projectState } from 'src/states/project';

import AnnotatorPanel from './AnnotatorPanel';
import JobList from './JobList';
import ProjectPanel from './ProjectPanel';

const Aside = (): JSX.Element => {
  const { user } = useRecoilValue(authState.signedMe);
  const assignedProjects = useRecoilValue(projectState.projects);
  const isAnnotating = useRecoilValue(jobState.isAnnotating);

  const [addReportModalVisibility, setAddReportModalVisibility] =
    useState<boolean>(false);
  const [reportListModalVisibility, setReportListModalVisibility] =
    useState<boolean>(false);

  const logout = useLogout();
  const { getConfirmation } = useConfirmModal();

  async function confirmLogout() {
    const logoutModalUnsavedProgress = {
      title: 'Log out and discard unsaved annotations',
      description:
        'Are you sure you want to discard all unsaved annotations and log out?',
      confirmButtonText: 'Discard & Log out',
    };
    const logoutModalDefaultCase = {
      title: 'Log out',
      description: 'Do you want to log out?',
      confirmButtonText: 'Log out',
    };

    const logoutModal = isAnnotating
      ? logoutModalUnsavedProgress
      : logoutModalDefaultCase;
    const confirmed = await getConfirmation(logoutModal);

    return confirmed && logout();
  }

  const toggleAddReportModal = () => {
    setAddReportModalVisibility(prev => !prev);
  };

  const toggleReportListModal = () => {
    setReportListModalVisibility(prev => !prev);
  };

  const logoutIconButton = (
    <Tooltip title="Logout" placement="top-end">
      <ButtonBase onClick={confirmLogout} data-test-id="logout">
        <PowerSettingsNew fontSize="small" />
      </ButtonBase>
    </Tooltip>
  );

  return (
    <Container data-test-id="aside-container">
      <AddReportModal
        visibility={addReportModalVisibility}
        onClose={toggleAddReportModal}
      />
      <ReportListModal
        visibility={reportListModalVisibility}
        onClose={toggleReportListModal}
      />

      <AsideItem label="User" iconButtons={[logoutIconButton]}>
        <UserName>{user.name || 'Unknown'}</UserName>
      </AsideItem>

      <ProjectPanel />
      <Suspense>
        <AnnotatorPanel />
      </Suspense>

      {assignedProjects.length !== 0 && (
        <AsideItem label="New Report" isCollapsible>
          <ButtonList>
            <ButtonListItem
              startIcon={<Add fontSize="small" />}
              onClick={toggleAddReportModal}
              data-test-id="report-job-btn"
            >
              Submit
            </ButtonListItem>
            <ButtonListItem
              startIcon={<ListIcon fontSize="small" />}
              onClick={toggleReportListModal}
            >
              Report List
            </ButtonListItem>
          </ButtonList>
        </AsideItem>
      )}
      <Suspense
        fallback={
          <AsideItem label="Job List">
            <JobListFallback>
              <CircularProgress
                size={30}
                color="inherit"
                data-test-id="jobList-loading-indicator"
              />
            </JobListFallback>
          </AsideItem>
        }
      >
        <JobList />
      </Suspense>
    </Container>
  );
};

export default Aside;

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-sizing: border-box;
  width: var(--ctl-aside-width);
  height: calc(100vh - var(--ctl-header-height) - var(--ctl-footer-height));
  background-color: var(--ctl-background-color-light);
`;

const JobListFallback = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const UserName = styled('div')`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
