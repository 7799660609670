import { useEffect, useState, MouseEvent } from 'react';

import cookie from 'js-cookie';
import { Form, useLocation, useNavigate } from 'react-router-dom';

import Button from '@mui/material/Button';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { styled } from '@mui/material/styles';

import { Footer } from 'src/components/forms/Footer';
import { FormPageContainer } from 'src/components/forms/FormPageContainer';
import LogoIcon from 'src/components/icons/LogoIcon';
import { modalityKey } from 'src/http';

// This page will be removed when BE is ready.
export default function Database(): JSX.Element {
  const location = useLocation();
  const navigate = useNavigate();

  const [modality, setModality] = useState<string>();

  useEffect(() => {
    if (location.state === undefined) navigate('/login');
  }, [navigate, location.state]);

  // This feature is a temporary solution
  const handleChangeModality = (
    _: MouseEvent<HTMLElement>,
    value: string | null
  ) => {
    if (value !== null) {
      setModality(value);
      cookie.set(modalityKey, value);
    }
  };

  return (
    <FormPageContainer>
      <h1>
        <LogoIcon />
      </h1>

      <StyledForm action="/workspace" reloadDocument>
        <ToggleButtonGroup
          fullWidth
          size="small"
          value={modality}
          exclusive
          onChange={handleChangeModality}
        >
          {location.state?.modalities.map((db: string) => (
            <StyledToggleButton value={db} key={db}>
              <ModalityWrap>{db}</ModalityWrap>
            </StyledToggleButton>
          ))}
        </ToggleButtonGroup>
        <Button
          type="submit"
          variant="contained"
          size="large"
          disabled={!modality}
        >
          Go to workspace
        </Button>
      </StyledForm>
      <Footer />
    </FormPageContainer>
  );
}

const StyledToggleButton = styled(ToggleButton)`
  display: flex;
  padding: 0;
`;

const StyledForm = styled(Form)`
  margin-bottom: 50px;
`;

const ModalityWrap = styled('div')`
  flex: 1;
  padding: 0.35rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;
