import {
  ClaimCaseResponse,
  Image as RemoteImage,
  Finding as RemoteFinding,
} from '@lunit-io/ctl-api-interface';

import { CornerstoneImage } from '@InsightViewer/image/types';
import { Contour } from '@InsightViewer/types';

import { Label } from 'src/interfaces/label';
import { Viewer, OperationMode } from 'src/interfaces/project';
import { Tuple } from 'src/utils/typeHelper';

export type Image = Pick<RemoteImage, 'id' | 'path' | 'height' | 'width'>;

export type ViewPresetType =
  | '4-horizontal-viewer'
  | '4-matrix-viewer'
  | '2-viewer'
  | '8-viewer';

export type CornerstoneImageWithId = {
  cornerstoneImage: CornerstoneImage;
  id: string;
};

export enum FindingShape {
  POINT = 'point',
  LINE = 'line',
  POLYGON = 'polygon',
  MULTI_FRAME_POLYGON = 'multiFramePolygon',
  BOX = 'box',
}

export interface LocalFinding extends RemoteFinding {
  // Internally, we manage finding with unique index for each mode.
  index: number;
  alias?: string;
  confirmed?: boolean;
  hidden?: boolean;
}

export type FindingContour = Contour &
  Pick<LocalFinding, 'shape' | 'viewOnly' | 'hidden' | 'group'>;

export type SetGroupDropResult = {
  type: 'SET_GROUP';
  group: string;
};

export type UnsetGroupDropResult = {
  type: 'UNSET_GROUP';
};

export type DropItem = {
  index: number;
  view: string;
  group: string;
};

export type DropResult = SetGroupDropResult | UnsetGroupDropResult;

export interface RemoteJob {
  id: string;
  project: string;
  case: string;
  reading?: string | null;
  user?: string; // TODO : Need to be checked. This is necessary or not
  type: string;
  reported: boolean;
  createdAt: string;
  validated: boolean;
  validatedAt: string;
  inspectedBy: string | null;
  oldReadings: [];
  assignedTo: string;
  assignedAt: string;
  queuedAt: string;
  completed?: boolean;
}

export interface ListJobResponse {
  success: RemoteJob[];
  failure: RemoteJob[];
}

interface Stats {
  total: number;
  createdStage: number;
  assignedStage: number;
  queuedStage: number;
  completedStage: number;
  reportedStage: number;
}

export interface JobStats {
  project: Stats;
  user: Stats;
}

export interface CaseMetadata {
  PixelSpacing: Tuple<number, 2>;
  SliceSpacing: number;
  WindowCenter: number;
  WindowWidth: number;
}

export type CaseAPIResponse = Pick<
  ClaimCaseResponse,
  'images' | 'report' | 'findings' | 'labels'
> & { metadata?: CaseMetadata; patientId: string; studyDate: string };

export interface LocalJob
  extends Pick<
      CaseAPIResponse,
      'images' | 'metadata' | 'report' | 'studyDate' | 'patientId'
    >,
    Pick<RemoteJob, 'id' | 'reported'> {
  findings: Record<string, LocalFinding[]>;
  labels: Record<string, Label[]>;
  annotationExists: boolean;
}

const groupByOperationMode = <T>(modes: OperationMode[]) => {
  return modes.reduce((accumulated: Record<string, T[]>, current) => {
    accumulated[current.name] = [];
    return accumulated;
  }, {});
};

export function getDefaultJob(
  viewer: Viewer,
  modes: OperationMode[]
): LocalJob {
  return {
    id: '',
    images: viewer.images.reduce(
      (accumulated: Record<string, Image | Image[]>, current) => {
        accumulated[current.name] = [];
        return accumulated;
      },
      {}
    ),
    report: '',
    findings: groupByOperationMode<LocalFinding>(modes),
    labels: groupByOperationMode<Label>(modes),
    annotationExists: false,
    reported: false,
    patientId: '',
    studyDate: '',
  };
}
