import { useState, useEffect, useRef, MouseEvent } from 'react';

import { ResetIcon } from '@lunit/opt-control-icons';
import { Virtuoso, VirtuosoHandle } from 'react-virtuoso';
import { useRecoilValue_TRANSITION_SUPPORT_UNSTABLE } from 'recoil';

import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import VerticalAlignBottomIcon from '@mui/icons-material/VerticalAlignBottom';
import ButtonBase from '@mui/material/ButtonBase';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

import AsideItem from 'src/components/aside/AsideItem';
import jobIdListState from 'src/states/jobIdList';
import { projectState } from 'src/states/project';
import {
  FlagMenu,
  flagMenu,
  IssueMenu,
  issueMenu,
  JobListIcons,
  StatusMenu,
  statusMenu,
} from 'src/utils/jobList';
import localStore from 'src/utils/localStore';

import JobListItem from './JobListItem';
import useLocalJobList from './useLocalJobList';

const localFilterAreaVisibility = localStore.getFilterAreaVisibility();

const JobList = (): JSX.Element => {
  const currentJobIndex = useRecoilValue_TRANSITION_SUPPORT_UNSTABLE(
    jobIdListState.currentJobIndex
  );
  const annotationTypes = useRecoilValue_TRANSITION_SUPPORT_UNSTABLE(
    projectState.annotationTypes
  );

  const [isFilterAreaVisible, setIsFilterAreaVisible] = useState(
    localFilterAreaVisibility
  );

  const virtuoso = useRef<VirtuosoHandle>(null);
  const [status, setStatus] = useState<StatusMenu>('All');
  const [flag, setFlag] = useState<FlagMenu>('All');
  const [type, setType] = useState('All');
  const [issue, setIssue] = useState<IssueMenu>('All');

  const { localJobList, onToggleFlagged } = useLocalJobList();

  const scrollToIndex = (index: number | null) => {
    virtuoso?.current?.scrollToIndex({
      index: index || 0,
      align: 'center',
      behavior: 'smooth',
    });
  };

  const handleChangeStatus = (
    event: MouseEvent<HTMLElement>,
    value: StatusMenu | null
  ) => {
    if (value !== null) {
      setStatus(value);
      scrollToIndex(0);
    }
  };
  const handleChangeFlag = (
    event: MouseEvent<HTMLElement>,
    value: FlagMenu | null
  ) => {
    if (value !== null) {
      setFlag(value);
      scrollToIndex(0);
    }
  };
  const handleChangeType = (
    event: MouseEvent<HTMLElement>,
    value: string | null
  ) => {
    if (value !== null) {
      setType(value);
      scrollToIndex(0);
    }
  };
  const handleChangeIssue = (
    event: MouseEvent<HTMLElement>,
    value: IssueMenu | null
  ) => {
    if (value !== null) {
      setIssue(value);
      scrollToIndex(0);
    }
  };

  const toggleFilterArea = () => {
    setIsFilterAreaVisible(prev => {
      localStore.setFilterAreaVisibility(!prev);
      return !prev;
    });
  };

  const jobItems = localJobList
    .filter(({ job }) => {
      switch (status) {
        case 'Reported':
          return job.reported;
        case 'Completed':
          return !job.reported && !!job.completed;
        case 'To do':
          return !job.reported && !job.completed;
        default:
          return true;
      }
    })
    .filter(({ flagged }) => {
      switch (flag) {
        case 'Flagged':
          return flagged;
        case 'Unflagged':
          return !flagged;
        default:
          return true;
      }
    })
    .filter(({ job }) => {
      switch (type) {
        case 'Multi Frame Polygon':
          return !!job.findingCounts?.multiFramePolygon;
        case 'Polygon':
          return !!job.findingCounts?.polygon;
        case 'Line':
          return !!job.findingCounts?.line;
        case 'Point':
          return !!job.findingCounts?.point;
        case 'Bounding Box':
          return !!job.findingCounts?.box;
        default:
          return true;
      }
    })
    .filter(({ job }) => {
      switch (issue) {
        case 'New issue':
          return job.issues && job.issues?.filter(f => !f.closed).length !== 0;
        case 'No new issue':
          return !job.issues || job.issues?.filter(f => !f.closed).length === 0;
        default:
          return true;
      }
    });

  const handleResetFilter = () => {
    setStatus('All');
    setFlag('All');
    setType('All');
    setIssue('All');
  };

  const scrollToBottom = () => scrollToIndex(localJobList.length - 1);
  const scrollToTop = () => scrollToIndex(0);

  const iconButtons = [
    <Tooltip title="Reset filters" placement="top-end">
      <ButtonBase onClick={handleResetFilter}>
        <ResetIcon fontSize="small" />
      </ButtonBase>
    </Tooltip>,
    <Tooltip title="Go to the bottom" placement="top-end">
      <ButtonBase onClick={scrollToBottom}>
        <VerticalAlignBottomIcon fontSize="small" />
      </ButtonBase>
    </Tooltip>,
    <Tooltip title="Go to the top" placement="top-end">
      <ButtonBase onClick={scrollToTop}>
        <VerticalAlignBottomIcon
          style={{
            transform: 'rotate(180deg)',
          }}
          fontSize="small"
        />
      </ButtonBase>
    </Tooltip>,
    <Tooltip
      title={isFilterAreaVisible ? 'Hide filters' : 'Show filters'}
      placement="top-end"
    >
      <ButtonBase onClick={toggleFilterArea}>
        {isFilterAreaVisible ? (
          <FilterAltIcon fontSize="small" />
        ) : (
          <FilterAltOffIcon fontSize="small" />
        )}
      </ButtonBase>
    </Tooltip>,
  ];

  useEffect(() => {
    scrollToIndex(currentJobIndex);
  }, [currentJobIndex]);

  return (
    <AsideItem
      label="Job List"
      iconButtons={iconButtons}
      style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {isFilterAreaVisible && (
        <FilterDiv>
          <ToggleButtonGroup
            fullWidth
            size="small"
            value={status}
            exclusive
            onChange={handleChangeStatus}
          >
            {statusMenu.map(status => (
              <StyledToggleButton value={status} key={status}>
                <Tooltip title={status} placement="top">
                  <ToggleButtonIconWrap>
                    {JobListIcons.getJobStatus(status)}
                  </ToggleButtonIconWrap>
                </Tooltip>
              </StyledToggleButton>
            ))}
          </ToggleButtonGroup>
          <ToggleButtonGroup
            fullWidth
            size="small"
            value={type}
            exclusive
            onChange={handleChangeType}
          >
            {['All', ...annotationTypes].map(type => (
              <StyledToggleButton value={type} key={type}>
                <Tooltip title={type} placement="top">
                  <ToggleButtonIconWrap>
                    {JobListIcons.getAnnotationType(type)}
                  </ToggleButtonIconWrap>
                </Tooltip>
              </StyledToggleButton>
            ))}
          </ToggleButtonGroup>
          <ToggleButtonGroup
            fullWidth
            size="small"
            value={flag}
            exclusive
            onChange={handleChangeFlag}
          >
            {flagMenu.map(flag => (
              <StyledToggleButton value={flag} key={flag}>
                <Tooltip title={flag} placement="top">
                  <ToggleButtonIconWrap>
                    {JobListIcons.getFlag(flag)}
                  </ToggleButtonIconWrap>
                </Tooltip>
              </StyledToggleButton>
            ))}
          </ToggleButtonGroup>
          <ToggleButtonGroup
            fullWidth
            size="small"
            value={issue}
            exclusive
            onChange={handleChangeIssue}
          >
            {issueMenu.map(issue => (
              <StyledToggleButton value={issue} key={issue}>
                <Tooltip title={issue} placement="top">
                  <ToggleButtonIconWrap>
                    {JobListIcons.getIssue(issue)}
                  </ToggleButtonIconWrap>
                </Tooltip>
              </StyledToggleButton>
            ))}
          </ToggleButtonGroup>
        </FilterDiv>
      )}
      <TotalDiv>
        {Number(jobItems.length).toLocaleString()} items out of{' '}
        {Number(localJobList.length).toLocaleString()} jobs
      </TotalDiv>

      <div style={{ flex: 1 }}>
        <Virtuoso
          ref={virtuoso}
          style={{ height: '100%' }}
          totalCount={jobItems.length}
          data={jobItems}
          itemContent={(index, jobItem) => {
            if (!jobItem) {
              return null;
            }
            return (
              <JobListItem
                key={index}
                job={jobItem.job}
                index={jobItem.index}
                style={{ marginBottom: 2 }}
                flagged={jobItem.flagged}
                onToggleFlagged={onToggleFlagged}
              />
            );
          }}
        />
      </div>
    </AsideItem>
  );
};

export default JobList;

const FilterDiv = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  margin-bottom: 1rem;
`;

const TotalDiv = styled('div')`
  margin: 0 0 0.5rem;
  color: var(--ctl-color);
`;

const StyledToggleButton = styled(ToggleButton)`
  display: flex;
  padding: 0;
`;
const ToggleButtonIconWrap = styled('div')`
  flex: 1;
  padding: 0.35rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;
