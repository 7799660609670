import { useRecoilValue } from 'recoil';

import NotificationsIcon from '@mui/icons-material/Notifications';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

import ButtonListIconButton from 'src/components/task/ButtonListIconButton';
import ButtonListItem from 'src/components/task/ButtonListItem';
import useMoveByJobId from 'src/hooks/currentJobId/useMoveByJobId';
import useAlert from 'src/hooks/useAlert';
import {
  changeJobFlag,
  JobListItem as JobListItemType,
} from 'src/services/job';
import jobIdListState from 'src/states/jobIdList';
import { projectState } from 'src/states/project';
import { JobListIcons } from 'src/utils/jobList';

interface Props {
  job: JobListItemType;
  index: number;
  style?: React.CSSProperties;
  flagged: boolean;
  onToggleFlagged: (jobId: string) => void;
}

const getAnnotationCount = (
  job: JobListItemType | undefined,
  type: string
): number => {
  if (!job) return 0;
  switch (type) {
    case 'Multi Frame Polygon':
      return job.findingCounts?.multiFramePolygon || 0;
    case 'Polygon':
      return job.findingCounts?.polygon || 0;
    case 'Point':
      return job.findingCounts?.point || 0;
    case 'Line':
      return job.findingCounts?.line || 0;
    case 'Bounding Box':
      return job.findingCounts?.box || 0;
    default:
      return 0;
  }
};

const JobListItem = ({
  job,
  index,
  style,
  flagged,
  onToggleFlagged,
}: Props): JSX.Element => {
  const annotationTypes = useRecoilValue(projectState.annotationTypes);
  const isReviewer = useRecoilValue(projectState.isReviewer);
  const currentJobId = useRecoilValue(jobIdListState.currentJobId);

  const { open: openAlert } = useAlert();

  const moveByJobId = useMoveByJobId();
  const handleClickJob = () => {
    moveByJobId(job.id);
  };

  const handleClickFlag = () => {
    if (isReviewer) return;
    try {
      onToggleFlagged(job.id);
      changeJobFlag({ jobId: job.id, flag: !flagged });
    } catch (error) {
      openAlert({
        type: 'error',
        message: `Toggle flagging state has been failed. (${
          (error as Error).message || error
        })`,
      });
      onToggleFlagged(job.id);
    }
  };

  return (
    <div style={style}>
      <ButtonListItem
        startIcon={<IndexDiv>{index + 1}</IndexDiv>}
        onClick={handleClickJob}
        selected={currentJobId === job?.id}
        startButton={
          <ButtonListIconButton onClick={handleClickFlag} disabled={isReviewer}>
            {flagged
              ? JobListIcons.getFlag('Flagged')
              : JobListIcons.getFlag('Unflagged')}
          </ButtonListIconButton>
        }
        data-test-id="job-list-item"
      >
        <AlignItems>
          {annotationTypes.map(type => (
            <Tooltip key={type} title={type}>
              <TypeDiv>
                {JobListIcons.getAnnotationType(type)}
                <CountDiv>{getAnnotationCount(job, type)}</CountDiv>
              </TypeDiv>
            </Tooltip>
          ))}
          <Tooltip title="New issues">
            <TypeDiv>
              <NotificationsIcon fontSize="inherit" />
              <CountDiv>
                {job.issues?.filter(f => !f.closed).length || 0}
              </CountDiv>
            </TypeDiv>
          </Tooltip>
        </AlignItems>
        {job?.reported ? (
          <Tooltip title="Reported" placement="right">
            <IconDiv>{JobListIcons.getJobStatus('Reported')}</IconDiv>
          </Tooltip>
        ) : job?.completed ? (
          <Tooltip title="Completed" placement="right">
            <IconDiv>{JobListIcons.getJobStatus('Completed')}</IconDiv>
          </Tooltip>
        ) : (
          <Tooltip title="To do" placement="right">
            <IconDiv>{JobListIcons.getJobStatus('To do')}</IconDiv>
          </Tooltip>
        )}
      </ButtonListItem>
    </div>
  );
};

export default JobListItem;

const IndexDiv = styled('div')`
  font-size: 1rem;
`;

const AlignItems = styled('div')`
  flex: 1;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
`;

const IconDiv = styled('div')`
  display: flex;
  align-items: center;
`;

const TypeDiv = styled('div')`
  display: flex;
  align-items: center;
  font-size: 1.125rem;
  gap: 0.25rem;
`;

const CountDiv = styled('div')`
  font-size: 1rem;
`;
