import { Fragment } from 'react';

import dayjs from 'dayjs';
import { useRecoilValue } from 'recoil';

import { styled } from '@mui/material/styles';

import AsideItem from 'src/components/aside/AsideItem';
import { jobState } from 'src/states/job';
import { projectState } from 'src/states/project';
import { isCPCPriorCaseWindow, thisCPCWindow } from 'src/utils/cpc';

import CopyToClipboardButton from './CopyToClipboardButton';

const InformationPanel = (): JSX.Element => {
  const { id, patientId, studyDate } = useRecoilValue(jobState.current);
  const isCPCProject = useRecoilValue(projectState.isCPC);
  const timeSpent = useRecoilValue(jobState.timeSpent);

  const items = [
    { title: 'Study Date', body: studyDate },
    { title: 'Job ID', body: id },
    { title: 'Patient ID', body: patientId },
  ];

  if (isCPCProject) {
    items.push({ title: 'Case', body: thisCPCWindow.label });
  }

  if (!isCPCPriorCaseWindow) {
    items.push({ title: 'Time spent', body: dayjs(timeSpent).format('mm:ss') });
  }

  return (
    <AsideItem label="Information" isCollapsible>
      <TextList role="list">
        {items.map(({ title, body }, index) => (
          <Fragment key={index}>
            <TextListTitle>{title}</TextListTitle>
            <TestListBody>
              {body}
              <span>
                <CopyToClipboardButton text={body} />
              </span>
            </TestListBody>
          </Fragment>
        ))}
      </TextList>
    </AsideItem>
  );
};

export default InformationPanel;

const TextList = styled('div')`
  display: grid;
  grid-template-columns: auto auto;
  justify-items: start;
  gap: 0.25rem 0.5rem;

  font-size: 0.875rem;
  letter-spacing: -0.125px;

  color: var(--ctl-color);
`;

const TextListTitle = styled('div')``;

const TestListBody = styled('div')`
  word-break: break-all;
  letter-spacing: -0.125px;

  &:hover {
    > span {
      opacity: 1;
      transform: translateX(0);
    }
  }

  > span {
    display: inline-block;
    vertical-align: top;
    margin-left: 0.25rem;
    opacity: 0;
    transform: translateX(-35%);
    transition:
      opacity var(--ctl-transition-timing),
      transform var(--ctl-transition-timing);
  }
`;
